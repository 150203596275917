<template>
  <div class="d-flex msgContent">
    <div
      class="flexList msgLabel"
      v-for="item in List"
      :key="item.zddm"
      :class="'w' + item.width"
    >
      <div class="msgName ">
        {{ item.name }}
      </div>
      <div class="msgVal flexList line1" :title="msData[item.zddm]">
        <span v-if="item.zddm != 'msfj'">{{ msData[item.zddm] }}</span>
        <a
          v-if="item.zddm == 'msfj'"
          @click="$yzImgDialog().show(getFileUrl(msData[item.zddm]))"
          href="javascript:;"
          >查看文件</a
        >
      </div>
    </div>
  </div>
</template>

<script>
import { getUserExamFree } from "@/api/examination_user.js";
export default {
  model: {
    prop: "form",
    event: "input",
  },
  props: {
    ksbmbh: {
      type: String,
    },
    form: Object,
  },
  watch: {
    form: {
      immediate:true,
      handler(val) {
        if (val && val.mssqzt != -1 && this.firstFlag) {
          this.firstFlag = false
          this.getmsData();
        }
      },
    },
  },
  data() {
    return {
      firstFlag: true,
      List: [],
      dataList1: [
        {
          name: "免试理由",
          zddm: "msyy",
          width: "50",
        },
        {
          name: "资格证书名称",
          zddm: "zsmc",
          width: "50",
        },
        {
          name: "资格证书编号",
          zddm: "zsbh",
          width: "50",
        },
        {
          name: "证书专业名称",
          zddm: "zymc",
          width: "50",
        },
        {
          name: "证书取得时间",
          zddm: "qdsj",
          width: "50",
        },
        {
          name: "证明电子材料",
          zddm: "msfj",
          width: "50",
        },
      ],
      dataList2: [
        {
          name: "免试理由",
          zddm: "msyy",
          width: "50",
        },
        {
          name: "毕业院校",
          zddm: "zsmc",
          width: "50",
        },
        {
          name: "证书编号",
          zddm: "zsbh",
          width: "50",
        },
        {
          name: "所学专业",
          zddm: "zymc",
          width: "50",
        },
        {
          name: "毕业时间",
          zddm: "qdsj",
          width: "50",
        },
        {
          name: "证明电子材料",
          zddm: "msfj",
          width: "50",
        },
      ],
      msData: {},
    };
  },
  methods: {
    getmsData() {
      getUserExamFree({bmh:this.form.bmh}).then((res) => {
        if (res.status) {
          this.msData = res.data.filter((v) => {
            return v.ksbmbh == this.form.ksbmbh;
          })[0];
          if (this.msData && this.msData.txlx == 1) {
            this.List = this.dataList1;
          } else {
            this.List = this.dataList2;
          }
        }
      });
    },
  },
};
</script>

<style>
.w50 {
  width: 50%;
}

.w100 {
  width: 100%;
}


</style>
<style scoped>
  .msgLabel{
    min-height: 45px;
    line-height: normal;
    height: auto;
  }
</style>

