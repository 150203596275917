<template>
  <div>
    <div class="pb-3  check-table-top border-dash mb-2">
      <div class="flexList" style="flex: 1;">
        <el-select class=" mr-2 blueBorder" clearable @change="dwChange" filterable style="width: 110px;"
          placeholder="请选择单位" v-model="pageData.bkdwzym" size="small" v-if="kmxzfs==2">
          <el-option value="" label="所有单位"></el-option>
          <el-option v-for="item in dwList" :key="item.value" :value="item.value" :label="item.name"></el-option>
        </el-select>

        <el-select class=" mr-2 blueBorder" clearable filterable style="width: 110px;" @change="test"
          placeholder="请选择职位" v-model="pageData.bkgwxkm" size="small" v-if="kmxzfs==2">
          <el-option value="" label="所有职位"></el-option>
          <el-option v-for="item in zwList" :key="item.value" :value="item.value" :label="item.name"></el-option>
        </el-select>
        <el-select class=" mr-2 blueBorder" filterable style="width: 10%;" clearable  placeholder="请选择级别"
          v-model="pageData.bkjbm" size="small" v-if="kmxzfs==1">
          <el-option :value="item.jbm" v-for="item in jbList" :key="item.value" :label="item.jbmc">
            {{item.jbmc}}
          </el-option>
        </el-select>
        <el-select class=" mr-2 blueBorder" filterable style="width: 10%;" clearable placeholder="请选择专业"
          v-model="pageData.bkdwzym" size="small" v-if="kmxzfs==1">
          <el-option :value="item.zym" v-for="item in classificationList" :key="item.value" :label="item.zymc">
            {{item.zymc}}
          </el-option>
        </el-select>
        <input placeholder="输入姓名或身份证" style="width: 9%" v-model="pageData.keyWord" class="h30 form-control border-blue mr-2"
          maxlength="50" />
        <el-select class=" mr-2 blueBorder" style="width: 7%" clearable  placeholder="档案号" v-model="pageData.dah"
          size="small">
          <el-option value="" label="所有"></el-option>
          <el-option value="1" label="有档案号"></el-option>
          <el-option value="0" label="无档案号"></el-option>
        </el-select>
        <el-select class=" mr-2 blueBorder" style="width: 7%" clearable  placeholder="照片审核" v-model="pageData.zpshzt"
          size="small">
          <el-option value="" label="照片审核"></el-option>
          <el-option value="1" label="通过"></el-option>
          <el-option value="2" label="不通过"></el-option>
          <el-option value="0" label="待审核"></el-option>
        </el-select>

        <el-select class=" mr-2 blueBorder" style="width: 7%" clearable  placeholder="资格审核" v-model="pageData.zgshzt"
          size="small">
          <el-option value="" label="资格审核"></el-option>
          <el-option value="1" label="通过"></el-option>
          <el-option value="2" label="不通过"></el-option>
          <el-option value="9" label="锁定"></el-option>
          <el-option value="0" label="待审核"></el-option>
        </el-select>
        <el-select class=" mr-2 blueBorder" style="width: 7%" clearable  placeholder="缴费状态" v-model="pageData.jfzt"
          size="small">
          <el-option value="" label="缴费状态"></el-option>
          <el-option value="1" label="已缴费"></el-option>
          <el-option value="0" label="待缴费"></el-option>
          <el-option value="9" label="已退费"></el-option>
        </el-select>
        <el-select class=" mr-2 blueBorder" style="width: 7%" clearable  placeholder="减免状态" v-model="pageData.jmzt"
                   size="small">
          <el-option value="" label="全部"></el-option>
<!--          <el-option value="1" label="未申请"></el-option>-->
          <el-option value="0" label="待审核"></el-option>
          <el-option value="1" label="通过"></el-option>
          <el-option value="2" label="不通过"></el-option>
        </el-select>
        <el-select class=" mr-2 blueBorder" style="width: 7%" clearable placeholder="筛选重复" v-model="pageData.sfcf"
          size="small">
          <el-option value="" label="所有"></el-option>
          <el-option value="1" label="重复"></el-option>
        </el-select>
        <el-select class=" mr-2 blueBorder" style="width: 7%" clearable placeholder="报名来源" v-model="pageData.bmly"
                   size="small" v-if="!isPay">
          <el-option value="网站" label="网站"></el-option>
          <el-option value="小程序" label="小程序"></el-option>
        </el-select>
        <button type="button" class="btn btn-info h30 flexList mr-2 w-xs" @click="searchOut">
          <i class="iconfont mr-2 icon-mb-search"></i>查询
        </button>
        <b-button @click="$refs.yzConditionSearch.show(conditionList)" variant="outline-info"
          class="flexList mr-2 condition w-md"><i class="iconfont icon-fasfa-chevron-circle-down mr-2"></i>更多条件
        </b-button>
        <b-button @click="$refs.statisticsMold.show()" variant="outline-info" class="flexList mr-2 condition"><i
            class="iconfont mr-2"></i>资格审核统计</b-button>
        <div class="custom-control custom-checkbox w-10" v-if="showDialog">
          <input type="checkbox" class="custom-control-input" id="customCheck1" checked v-model="isDialog"
            @change="isDialogChange" />
          <label class="custom-control-label" for="customCheck1">弹窗审核</label>
        </div>
      </div>
      <div class="d-flex">
        <div class="border-blue export-tab" v-if="!this.isOrg" @click="exportClick('xlsx')">
          <i class="iconfont icon-antOutline-file-excel mr-2"></i>excel
        </div>
        <div class="border-blue export-tab" v-if="!this.isOrg" @click="exportClick('dbf')">
          <i class="iconfont icon-data mr-2"></i>dbf
        </div>
        <div class="zidingyi-icon" v-if="moduleNum!='7'"><i class=" iconfont icon-icon_shezhi" title="显示项设置"
            v-b-modal.tableSet @click="selectconditin()"></i>
        </div>
      </div>

    </div>
    <div class="d-flex justify-content-between mb-3" v-if="!isPay">
      <div class="flexList">
        <label class="mr-2">批量操作：</label>
        <b-button variant="outline-success" class="flexList mr-2 h30" @click="printAll"><i
            class="iconfont icon-antOutline-printer mr-2"></i>报名表批量下载</b-button>
      </div>
    </div>
    <YzConditionSearch ref="yzConditionSearch" @search="getListByCondition"></YzConditionSearch>
    <QualificationAuditStatistics ref="statisticsMold"></QualificationAuditStatistics>
  </div>


</template>

<script>
  import {
    getPosition
  } from "@/api/examination_user";
  import {
    getUnit
  } from "@/api/examination_user";
  import {
    classificationEasyList
  } from "@/api/admin/base/classification";
  import YzConditionSearch from "@/components/form/yzConditionSearch.vue";
  import {
    getExportPower
  } from "@/api/admin/exam/checkPower"
  import {
    exportBmkFields
  } from '@/api/common/public'
  import QualificationAuditStatistics from "@/views/pages/admin/statistics/QualificationAuditStatistics.vue";
  export default {
    name: "check-query.vue",
    model: {
      prop: "pageData",
      event: "change",
    },
    components: {
      QualificationAuditStatistics,
      YzConditionSearch,
    },
    props: {
      kmxzfs: Number,
      ksbmbh: String,
      ksmc: String,
      pageData: Object,
      isOrg: {
        type: Boolean,
        default: false
      },
      isPay: {
        type: Boolean,
        default: false
      },
      flbm: String,
      showDialog: {
        default: true,
      },
      moduleNum: String,
      checkList: Array

    },
    data() {
      return {
        dwList: [],
        zwList: [],
        jbList: [],
        isUserExportPower: false,
        isDialog: true,
        classificationList: [],
        exportTitle: this.ksmc + "导出",
        module: "BMZYB",
        dwObj: {},
        conditionList: [{
            name: "政治面貌",
            type: "select",
            val: "zzmmm",
            glzdb: "dict_zzmm"
          },
          {
            name: "民族",
            type: "select",
            val: "mzm",
            glzdb: "dict_mz"
          },
          {
            name: "性别",
            type: "radio",
            val: "xbm",
            glzdb: "dict_xb",
          },
          {
            name: "学历",
            type: "select",
            val: "zgxlm",
            glzdb: "dict_xl",
          },
          {
            name: "专业",
            type: "input",
            val: "bkgwxk",
          },
          {
            name: "学位",
            type: "select",
            val: "zgxwm",
            glzdb: "dict_xw"
          },
          {
            name: "毕业院校",
            type: "input",
            val: "zhbyxxmc"
          },
          {
            name: "证件类型",
            type: "select",
            val: "sfzjlxm",
            glzdb: "dict_sfzjlx",
          },
          {
            name: "工作单位",
            type: "select",
            val: "bkdwzy",
            glzdb: "bkdw"
          },
          {
            name: "职位编码",
            type: "input",
            val: "bkgwxkm",
            // glzdb: "bkzw"
          },
          // {
          //   name: "报考职位",
          //   type: "select",
          //   val: "bkgwxk",
          //   glzdb: "bkzw"
          // },
          {
            name: "报考时间",
            type: "date",
            val: "create_time"
          },
          {
            name: "报考考区",
            type: "input",
            val: "kqmc",
          },
          // {
          //   name: "刑事处罚",
          //   type: "radio",
          //   val: "ywxscfjl"
          // },
          // {
          //   name: "党纪处分",
          //   type: "radio",
          //   val: "ywdjzjcfjl"
          // },
        ],
      }
    },
    methods: {
      printAll() {
        this.$emit('printAll')
      },
      // 获取单位


      getDw() {
        getUnit({
          ksbmbh: this.ksbmbh
        }).then(res => {
          if (res.status) {
            this.dwList = res.data;

          }
        });
      },
      exportClick(type) {
        this.$exportModal().show({
          title: this.exportTitle,
          type: type,
          module: this.module,
          ksbmbh: this.ksbmbh,
          condition: this.pageData
        })
      },
      dwChange(e) {
        this.pageData['bkgwxkm'] = ''
        if (this.pageData.bkdwzym) {
          this.zwList = this.dwObj[e].zwlist || []
        }
      },
      test() {
        this.$forceUpdate()
      },
      // 获取职位
      getZw() {
        getPosition(this.ksbmbh).then((res) => {
          if(res.status){
            let data = res.data;
            this.zwList = [];
            this.dwList = [];
            this.dwObj = {}
            let dwobj = {};
            if (data) {
              data.forEach((item) => {
                item.name = item.zwmc + "(" + item.zwbm + ")";
                item.value = item.zwbm;
                if (dwobj[item.bkdwdm]) {
                  dwobj[item.bkdwdm].zwlist.push(item);
                } else {
                  dwobj[item.bkdwdm] = {
                    name: item.bkdw,
                    value: item.bkdwdm,
                    zwlist: [item],
                  };
                }
              });
            }

            this.dwObj = dwobj
            this.dwList = Object.values(dwobj);
            // this.zwList = data
          }

        });

      },


      // 选科筛选
      getJb(params) {
        classificationEasyList({
          flbm: params,
        }).then(res => {
          if (res.status) {
            let newData = res.data
            let obj = {}
            newData.forEach(v => {
              if (!obj[v.jbm]) {
                obj[v.jbm] = {
                  jbm: v.jbm,
                  jbmc: v.jbmc
                }
              }
            })
            this.jbList = Object.values(obj)
          }
        })
      },
      // 查专业
      getClassList() {
        let paramsObj = {
          flbm: this.flbm,
          jbm: this.pageData.jbm,
          zym: this.pageData.zym
        }
        classificationEasyList(paramsObj).then(res => {
          if (res.status) {
            let newData = res.data
            let obj = {}
            newData.forEach(v => {
              if (!obj[v.zym]) {
                obj[v.zym] = {
                  zym: v.zym,
                  zymc: v.zymc
                }
              }
            })
            this.classificationList = Object.values(obj)
          }
        })
      },
      getListByCondition(e) {
        let pageData = {
          ...this.pageData,
          ...e
        }
        this.$refs.yzConditionSearch.hide()
        // this.$emit("change", pageData)
        this.getList(pageData)
      },
      searchOut() {
        // this.$refs.yzConditionSearch.query = {} //清楚query
        this.$refs.yzConditionSearch.domains = [{
          value: ''
        }] //清除渲染
        this.getList()
      },
      getList(more) {
        this.$emit("getList", more)
      },
      isDialogChange() {
        this.$emit("isDialogChange", this.isDialog)
      },
      getPower() {
        let powerShow = getExportPower()
        if (powerShow) {
          this.isUserExportPower = true
        }
      },
      // 看筛选条件是否在
      selectconditin() {
        exportBmkFields(this.ksbmbh).then(res => {
          let newList = []
          let list1 = []
          let list2 = []
          if (res.status) {
            list1 = res.data.registerFields
            list2 = res.data.statusFields
            newList.push(...list1, ...list2)
            // console.log(newList)
            let newConds = []
            newList.forEach(item => {
              let newArry = this.conditionList.filter(k => {
                return k['val'] == item.zddm
              })
              if (newArry.length) {
                newConds = [...newConds, ...newArry]
              }
            })
            this.conditionList = newConds
          }

        })
      },
      initSelectList() {
        // console.log(this.kmxzfs)
        if (this.kmxzfs == 1) {
          this.getJb()
          this.getClassList()
        }
        if (this.isOrg) {
          this.isDialog = true
        }
        this.$nextTick(() => {
          if (this.kmxzfs == 2) {
            // this.getDw()
            this.getZw()
          }
        })
        // if (this.ksbmbh) {
        //   this.selectconditin()
        // }
      }
    },
    mounted() {
      this.getPower()
    },
    watch: {
      kmxzfs: {
        immediate: true,
        handler(n, o) {
          if (n && !o) {
            this.initSelectList()
          }
        }
      },
      checkList: {
        handler(n, o) {
          this.checkList = n
          // console.log(n, o)
        }
      }
    }
  }
</script>

<style scoped>
  .el-select.blueBorder .el-input__inner {
    border-color: rgb(23, 118, 210);
    height: 30px;
  }
</style>
