<template>
  <div class="outerCotent mt-3 person-form card">
    <examForm
      v-model="form"
      :bmh="this.bmh"
      :ksbmbh="this.ksbmbh"
      :readOnly="false"
      @save="submitFrom"
    ></examForm>
  </div>
</template>

<script>
import examForm from "@/components/form/examForm.vue";

import { examApply, examEdit, getUserBmk } from "@/api/examination_user.js";

import { getExamDetails } from "@/api/admin/exam/examRecord.js";

import { getRegisterUserInfo } from "@/api/admin/register/registerUser.js";
export default {
  props: {
    ksbmbh: {
      type: String,
      default: "",
    },
    bmh: {
      type: String,
      default: "",
    },
  },
  components: {
    examForm,
  },

  data() {
    return {
      examInfo: "",
      form: {
        gznxzm: "",
        xlzm: "",
        qtzm: "",
      },
      astrict: {
        zpdz: {},
        xlzm: {},
        gznxzm: {},
      },
    };
  },
  methods: {
    submitFrom() {
      let _this = this;

      _this.form.sfzstj = true;
      if (_this.form.bmh) {
        examEdit(_this.form).then((res) => {
          if (res.status) {
            _this.$message({ type: "success", message: res.message });
          }
        });
      } else {
        examApply(_this.form).then((res) => {
          if (res.status) {
            _this.$router.push("./record");
            _this.$message({ type: "success", message: res.message });
          }
        });
      }
    },
    //获取考试详情
    getExamInfo() {
      getExamDetails(this.ksbmbh).then((res) => {
        if (res.status) {
          this.examInfo = res.data;
          this.init(this.bmh);
        }
      });
    },
    init(bmh) {
      getRegisterUserInfo(bmh).then((res) => {
        if (res.status) {
          let data = res.data;
          if (data) {
            this.sfty = data.sfty;
            if (this.examInfo.bmkbm) {
              getUserBmk({
                ksbmbh: this.ksbmbh,
                sfzjh: data.sfzjh,
                bmh:data.bmh
              }).then((res) => {
                if (res.status) {
                  let list = res.data[res.data.length - 1] || {};
                  for (const key in list) {
                    if (!data[key]) {
                      data[key] = list[key];
                    }
                  }
                  this.form = data;
                }
              });
            } else {
              this.form = data;
            }
          }
        }
      });
    },
  },

  created() {
    this.getExamInfo();
  },
};
</script>
