// 收费

import {
  getRequest,
  postRequest,
  putRequest,
  deleteRequest,
} from "@/libs/axios";
// 收费分页查询
export const getCharge = params => {
  return getRequest("/yethan/pay/feeRecord/listPage", params);
};
// 添加
export const addCharge = params => {
  return postRequest("/yethan/pay/feeRecord/bmh/", params);
};
// 修改
export const editCharge = params => {
  return putRequest("/yethan/pay/feeRecord", params);
};

// 批量收费
export const chargeMore = (bmh,params) => {
    return putRequest(`/yethan/pay/feeRecord/bmh/${bmh}?payType=`+params.payType);
};
// 收费摘要
export const getChargeInfo = bmh => {
  return getRequest(`/yethan/registerUser/${bmh}`);
};


// 取消收费，可批量
export const quitCharge  = bmh => {
    return deleteRequest(`/yethan/pay/feeRecord/bmh/${bmh}`);
};
