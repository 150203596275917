<script >
export default {
  props:{
    data: {
      default: ()=>{
        return {}
      }
    },
  },
};
</script>

<template>
  <body link="blue" vlink="purple" style="margin: 0 auto;padding: 10px;">
    <table border="0" cellpadding="0" cellspacing="0" style='width:100%;border-collapse:collapse;table-layout:fixed;'>
    <col width="120" style='width:120pt;'/>
    <col width="154.73" style='mso-width-source:userset;mso-width-alt:5658;'/>
    <col width="210" style='mso-width-source:userset;mso-width-alt:7679;'/>
    <col width="100" style='mso-width-source:userset;mso-width-alt:7594;'/>
    <tr height="74.67" style='height:56.00pt;mso-height-source:userset;mso-height-alt:1120;'>
      <td class="xl66" width="572.40" colspan="4" style='width:429.30pt;border-right:none;border-bottom:none;' x:str><span style='mso-spacerun:yes;'>{{data.ksmc}}</span><font class="font2">考试审核工作量统计表</font></td>
    </tr>
    <tr height="36" class="xl65" style='height:27.00pt;mso-height-source:userset;mso-height-alt:540;'>
      <td class="xl68" height="36" style='height:27.00pt;' x:str>主管部门</td>
      <td class="xl68" x:str>资格审核账号</td>
      <td class="xl68" x:str>资格审核人</td>
      <td class="xl68" x:str>审核数量（/人）</td>
    </tr>
    <tr height="36" class="xl65" style='height:27.00pt;mso-height-source:userset;mso-height-alt:540;' v-for="(v, i) in data.zgshmx" :key="i">
      <td class="xl68" height="36" style='height:27.00pt;white-space: normal' x:str>{{v.zgbmmc}}</td>
      <td class="xl68" x:str>{{v.zgshzh}}</td>
      <td class="xl68" x:str>{{v.zgshrmc}}</td>
      <td class="xl69" x:num>{{v.shzhsl}}</td>
    </tr>
      <tr height="36" style='height:27.00pt;mso-height-source:userset;mso-height-alt:540;'>
        <td class="xl68" height="36" colspan="3" style='height:27.00pt;border-right:.5pt solid windowtext;border-bottom:.5pt solid windowtext;' x:str>未审核人数合计</td>
        <td class="xl68" x:num>{{data.wshbms}}</td>
      </tr>
    <tr height="36" style='height:27.00pt;mso-height-source:userset;mso-height-alt:540;'>
      <td class="xl68" height="36" colspan="3" style='height:27.00pt;border-right:.5pt solid windowtext;border-bottom:.5pt solid windowtext;' x:str>合计</td>
      <td class="xl68" x:num>{{data.tjzs}}</td>
    </tr>
    <tr height="17.33" style='height:13.00pt;mso-height-source:userset;mso-height-alt:260;'>
      <td class="xl70" colspan="4" style='text-align: left' x:str>备注：以上统计数据是按报名数据（包含缴费和未缴费）统计得出</td>
    </tr>
    <tr width="0" style='display:none;'>
      <td width="155" style='width:116;'></td>
      <td width="210" style='width:158;'></td>
      <td width="208" style='width:156;'></td>
    </tr>
  </table>
  </body>
</template>
<style scoped>
@page
{margin:1.00in 0.75in 1.00in 0.75in;
  mso-header-margin:0.50in;
  mso-footer-margin:0.50in;
  mso-page-numbers-start:1;}
tr
{mso-height-source:auto;
  mso-ruby-visibility:none;}
col
{mso-width-source:auto;
  mso-ruby-visibility:none;}
br
{mso-data-placement:same-cell;}
.font0
{color:windowtext;
  font-size:10.0pt;
  font-weight:400;
  font-style:normal;
  text-decoration:none;
  font-family:"Arial";
  mso-generic-font-family:swiss;
  mso-font-charset:0;}
.font1
{color:windowtext;
  font-size:16.0pt;
  font-weight:700;
  font-style:normal;
  text-decoration:underline;
  text-underline-style:single;
  font-family:"宋体";
  mso-generic-font-family:auto;
  mso-font-charset:134;}
.font2
{color:windowtext;
  font-size:16.0pt;
  font-weight:700;
  font-style:normal;
  text-decoration:none;
  font-family:"宋体";
  mso-generic-font-family:auto;
  mso-font-charset:134;}
.font3
{color:windowtext;
  font-size:10.0pt;
  font-weight:400;
  font-style:normal;
  text-decoration:none;
  font-family:"宋体";
  mso-generic-font-family:auto;
  mso-font-charset:134;}
.font4
{color:#000000;
  font-size:11.0pt;
  font-weight:400;
  font-style:normal;
  text-decoration:none;
  font-family:"宋体";
  mso-generic-font-family:auto;
  mso-font-charset:134;}
.font5
{color:#3F3F76;
  font-size:11.0pt;
  font-weight:400;
  font-style:normal;
  text-decoration:none;
  font-family:"宋体";
  mso-generic-font-family:auto;
  mso-font-charset:134;}
.font6
{color:#9C0006;
  font-size:11.0pt;
  font-weight:400;
  font-style:normal;
  text-decoration:none;
  font-family:"宋体";
  mso-generic-font-family:auto;
  mso-font-charset:134;}
.font7
{color:#FFFFFF;
  font-size:11.0pt;
  font-weight:400;
  font-style:normal;
  text-decoration:none;
  font-family:"宋体";
  mso-generic-font-family:auto;
  mso-font-charset:134;}
.font8
{color:#0000FF;
  font-size:11.0pt;
  font-weight:400;
  font-style:normal;
  text-decoration:underline;
  text-underline-style:single;
  font-family:"宋体";
  mso-generic-font-family:auto;
  mso-font-charset:134;}
.font9
{color:#800080;
  font-size:11.0pt;
  font-weight:400;
  font-style:normal;
  text-decoration:underline;
  text-underline-style:single;
  font-family:"宋体";
  mso-generic-font-family:auto;
  mso-font-charset:134;}
.font10
{color:#000000;
  font-size:11.0pt;
  font-weight:400;
  font-style:normal;
  text-decoration:none;
  font-family:"宋体";
  mso-generic-font-family:auto;
  mso-font-charset:134;}
.font11
{color:#44546A;
  font-size:11.0pt;
  font-weight:700;
  font-style:normal;
  text-decoration:none;
  font-family:"宋体";
  mso-generic-font-family:auto;
  mso-font-charset:134;}
.font12
{color:#FF0000;
  font-size:11.0pt;
  font-weight:400;
  font-style:normal;
  text-decoration:none;
  font-family:"宋体";
  mso-generic-font-family:auto;
  mso-font-charset:134;}
.font13
{color:#44546A;
  font-size:18.0pt;
  font-weight:700;
  font-style:normal;
  text-decoration:none;
  font-family:"宋体";
  mso-generic-font-family:auto;
  mso-font-charset:134;}
.font14
{color:#7F7F7F;
  font-size:11.0pt;
  font-weight:400;
  font-style:italic;
  text-decoration:none;
  font-family:"宋体";
  mso-generic-font-family:auto;
  mso-font-charset:134;}
.font15
{color:#44546A;
  font-size:15.0pt;
  font-weight:700;
  font-style:normal;
  text-decoration:none;
  font-family:"宋体";
  mso-generic-font-family:auto;
  mso-font-charset:134;}
.font16
{color:#44546A;
  font-size:13.0pt;
  font-weight:700;
  font-style:normal;
  text-decoration:none;
  font-family:"宋体";
  mso-generic-font-family:auto;
  mso-font-charset:134;}
.font17
{color:#3F3F3F;
  font-size:11.0pt;
  font-weight:700;
  font-style:normal;
  text-decoration:none;
  font-family:"宋体";
  mso-generic-font-family:auto;
  mso-font-charset:134;}
.font18
{color:#FA7D00;
  font-size:11.0pt;
  font-weight:700;
  font-style:normal;
  text-decoration:none;
  font-family:"宋体";
  mso-generic-font-family:auto;
  mso-font-charset:134;}
.font19
{color:#FFFFFF;
  font-size:11.0pt;
  font-weight:700;
  font-style:normal;
  text-decoration:none;
  font-family:"宋体";
  mso-generic-font-family:auto;
  mso-font-charset:134;}
.font20
{color:#FA7D00;
  font-size:11.0pt;
  font-weight:400;
  font-style:normal;
  text-decoration:none;
  font-family:"宋体";
  mso-generic-font-family:auto;
  mso-font-charset:134;}
.font21
{color:#000000;
  font-size:11.0pt;
  font-weight:700;
  font-style:normal;
  text-decoration:none;
  font-family:"宋体";
  mso-generic-font-family:auto;
  mso-font-charset:134;}
.font22
{color:#006100;
  font-size:11.0pt;
  font-weight:400;
  font-style:normal;
  text-decoration:none;
  font-family:"宋体";
  mso-generic-font-family:auto;
  mso-font-charset:134;}
.font23
{color:#9C6500;
  font-size:11.0pt;
  font-weight:400;
  font-style:normal;
  text-decoration:none;
  font-family:"宋体";
  mso-generic-font-family:auto;
  mso-font-charset:134;}
.style0
{mso-number-format:"General";
  text-align:general;
  vertical-align:bottom;
  white-space:nowrap;
  mso-rotate:0;
  mso-pattern:auto;
  mso-background-source:auto;
  color:windowtext;
  font-size:10.0pt;
  font-weight:400;
  font-style:normal;
  text-decoration:none;
  font-family:Arial;
  mso-generic-font-family:swiss;
  mso-font-charset:0;
  border:none;
  mso-protection:locked visible;
  mso-style-name:"常规";
  mso-style-id:0;}
.style16
{mso-number-format:"_ \0022\00A5\0022* \#\,\#\#0_ \;_ \0022\00A5\0022* \\-\#\,\#\#0_ \;_ \0022\00A5\0022* \0022-\0022_ \;_ \@_ ";
  color:windowtext;
  font-size:10.0pt;
  font-weight:400;
  font-style:normal;
  text-decoration:none;
  font-family:Arial;
  mso-generic-font-family:swiss;
  mso-font-charset:0;
  mso-style-name:"货币[0]";
  mso-style-id:7;}
.style17
{mso-pattern:auto none;
  background:#EDEDED;
  color:#000000;
  font-size:11.0pt;
  font-weight:400;
  font-style:normal;
  text-decoration:none;
  font-family:宋体;
  mso-generic-font-family:auto;
  mso-font-charset:134;
  mso-style-name:"20% - 强调文字颜色 3";}
.style18
{mso-pattern:auto none;
  background:#FFCC99;
  color:#3F3F76;
  font-size:11.0pt;
  font-weight:400;
  font-style:normal;
  text-decoration:none;
  font-family:宋体;
  mso-generic-font-family:auto;
  mso-font-charset:134;
  border:.5pt solid #7F7F7F;
  mso-style-name:"输入";}
.style19
{mso-number-format:"_ \0022\00A5\0022* \#\,\#\#0\.00_ \;_ \0022\00A5\0022* \\-\#\,\#\#0\.00_ \;_ \0022\00A5\0022* \0022-\0022??_ \;_ \@_ ";
  color:windowtext;
  font-size:10.0pt;
  font-weight:400;
  font-style:normal;
  text-decoration:none;
  font-family:Arial;
  mso-generic-font-family:swiss;
  mso-font-charset:0;
  mso-style-name:"货币";
  mso-style-id:4;}
.style20
{mso-number-format:"_ * \#\,\#\#0_ \;_ * \\-\#\,\#\#0_ \;_ * \0022-\0022_ \;_ \@_ ";
  color:windowtext;
  font-size:10.0pt;
  font-weight:400;
  font-style:normal;
  text-decoration:none;
  font-family:Arial;
  mso-generic-font-family:swiss;
  mso-font-charset:0;
  mso-style-name:"千位分隔[0]";
  mso-style-id:6;}
.style21
{mso-pattern:auto none;
  background:#DBDBDB;
  color:#000000;
  font-size:11.0pt;
  font-weight:400;
  font-style:normal;
  text-decoration:none;
  font-family:宋体;
  mso-generic-font-family:auto;
  mso-font-charset:134;
  mso-style-name:"40% - 强调文字颜色 3";}
.style22
{mso-pattern:auto none;
  background:#FFC7CE;
  color:#9C0006;
  font-size:11.0pt;
  font-weight:400;
  font-style:normal;
  text-decoration:none;
  font-family:宋体;
  mso-generic-font-family:auto;
  mso-font-charset:134;
  mso-style-name:"差";}
.style23
{mso-number-format:"_ * \#\,\#\#0\.00_ \;_ * \\-\#\,\#\#0\.00_ \;_ * \0022-\0022??_ \;_ \@_ ";
  color:windowtext;
  font-size:10.0pt;
  font-weight:400;
  font-style:normal;
  text-decoration:none;
  font-family:Arial;
  mso-generic-font-family:swiss;
  mso-font-charset:0;
  mso-style-name:"千位分隔";
  mso-style-id:3;}
.style24
{mso-pattern:auto none;
  background:#C9C9C9;
  color:#FFFFFF;
  font-size:11.0pt;
  font-weight:400;
  font-style:normal;
  text-decoration:none;
  font-family:宋体;
  mso-generic-font-family:auto;
  mso-font-charset:134;
  mso-style-name:"60% - 强调文字颜色 3";}
.style25
{color:#0000FF;
  font-size:11.0pt;
  font-weight:400;
  font-style:normal;
  text-decoration:underline;
  text-underline-style:single;
  font-family:宋体;
  mso-generic-font-family:auto;
  mso-font-charset:134;
  mso-style-name:"超链接";
  mso-style-id:8;}
.style26
{mso-number-format:"0%";
  color:windowtext;
  font-size:10.0pt;
  font-weight:400;
  font-style:normal;
  text-decoration:none;
  font-family:Arial;
  mso-generic-font-family:swiss;
  mso-font-charset:0;
  mso-style-name:"百分比";
  mso-style-id:5;}
.style27
{color:#800080;
  font-size:11.0pt;
  font-weight:400;
  font-style:normal;
  text-decoration:underline;
  text-underline-style:single;
  font-family:宋体;
  mso-generic-font-family:auto;
  mso-font-charset:134;
  mso-style-name:"已访问的超链接";
  mso-style-id:9;}
.style28
{mso-pattern:auto none;
  background:#FFFFCC;
  border:.5pt solid #B2B2B2;
  mso-style-name:"注释";}
.style29
{mso-pattern:auto none;
  background:#F4B084;
  color:#FFFFFF;
  font-size:11.0pt;
  font-weight:400;
  font-style:normal;
  text-decoration:none;
  font-family:宋体;
  mso-generic-font-family:auto;
  mso-font-charset:134;
  mso-style-name:"60% - 强调文字颜色 2";}
.style30
{color:#44546A;
  font-size:11.0pt;
  font-weight:700;
  font-style:normal;
  text-decoration:none;
  font-family:宋体;
  mso-generic-font-family:auto;
  mso-font-charset:134;
  mso-style-name:"标题 4";}
.style31
{color:#FF0000;
  font-size:11.0pt;
  font-weight:400;
  font-style:normal;
  text-decoration:none;
  font-family:宋体;
  mso-generic-font-family:auto;
  mso-font-charset:134;
  mso-style-name:"警告文本";}
.style32
{color:#44546A;
  font-size:18.0pt;
  font-weight:700;
  font-style:normal;
  text-decoration:none;
  font-family:宋体;
  mso-generic-font-family:auto;
  mso-font-charset:134;
  mso-style-name:"标题";}
.style33
{color:#7F7F7F;
  font-size:11.0pt;
  font-weight:400;
  font-style:italic;
  text-decoration:none;
  font-family:宋体;
  mso-generic-font-family:auto;
  mso-font-charset:134;
  mso-style-name:"解释性文本";}
.style34
{color:#44546A;
  font-size:15.0pt;
  font-weight:700;
  font-style:normal;
  text-decoration:none;
  font-family:宋体;
  mso-generic-font-family:auto;
  mso-font-charset:134;
  border-bottom:1.0pt solid #5B9BD5;
  mso-style-name:"标题 1";}
.style35
{color:#44546A;
  font-size:13.0pt;
  font-weight:700;
  font-style:normal;
  text-decoration:none;
  font-family:宋体;
  mso-generic-font-family:auto;
  mso-font-charset:134;
  border-bottom:1.0pt solid #5B9BD5;
  mso-style-name:"标题 2";}
.style36
{mso-pattern:auto none;
  background:#9BC2E6;
  color:#FFFFFF;
  font-size:11.0pt;
  font-weight:400;
  font-style:normal;
  text-decoration:none;
  font-family:宋体;
  mso-generic-font-family:auto;
  mso-font-charset:134;
  mso-style-name:"60% - 强调文字颜色 1";}
.style37
{color:#44546A;
  font-size:11.0pt;
  font-weight:700;
  font-style:normal;
  text-decoration:none;
  font-family:宋体;
  mso-generic-font-family:auto;
  mso-font-charset:134;
  border-bottom:1.0pt solid #ACCCEA;
  mso-style-name:"标题 3";}
.style38
{mso-pattern:auto none;
  background:#FFD966;
  color:#FFFFFF;
  font-size:11.0pt;
  font-weight:400;
  font-style:normal;
  text-decoration:none;
  font-family:宋体;
  mso-generic-font-family:auto;
  mso-font-charset:134;
  mso-style-name:"60% - 强调文字颜色 4";}
.style39
{mso-pattern:auto none;
  background:#F2F2F2;
  color:#3F3F3F;
  font-size:11.0pt;
  font-weight:700;
  font-style:normal;
  text-decoration:none;
  font-family:宋体;
  mso-generic-font-family:auto;
  mso-font-charset:134;
  border:.5pt solid #3F3F3F;
  mso-style-name:"输出";}
.style40
{mso-pattern:auto none;
  background:#F2F2F2;
  color:#FA7D00;
  font-size:11.0pt;
  font-weight:700;
  font-style:normal;
  text-decoration:none;
  font-family:宋体;
  mso-generic-font-family:auto;
  mso-font-charset:134;
  border:.5pt solid #7F7F7F;
  mso-style-name:"计算";}
.style41
{mso-pattern:auto none;
  background:#A5A5A5;
  color:#FFFFFF;
  font-size:11.0pt;
  font-weight:700;
  font-style:normal;
  text-decoration:none;
  font-family:宋体;
  mso-generic-font-family:auto;
  mso-font-charset:134;
  border:2.0pt double #3F3F3F;
  mso-style-name:"检查单元格";}
.style42
{mso-pattern:auto none;
  background:#E2EFDA;
  color:#000000;
  font-size:11.0pt;
  font-weight:400;
  font-style:normal;
  text-decoration:none;
  font-family:宋体;
  mso-generic-font-family:auto;
  mso-font-charset:134;
  mso-style-name:"20% - 强调文字颜色 6";}
.style43
{mso-pattern:auto none;
  background:#ED7D31;
  color:#FFFFFF;
  font-size:11.0pt;
  font-weight:400;
  font-style:normal;
  text-decoration:none;
  font-family:宋体;
  mso-generic-font-family:auto;
  mso-font-charset:134;
  mso-style-name:"强调文字颜色 2";}
.style44
{color:#FA7D00;
  font-size:11.0pt;
  font-weight:400;
  font-style:normal;
  text-decoration:none;
  font-family:宋体;
  mso-generic-font-family:auto;
  mso-font-charset:134;
  border-bottom:2.0pt double #FF8001;
  mso-style-name:"链接单元格";}
.style45
{color:#000000;
  font-size:11.0pt;
  font-weight:700;
  font-style:normal;
  text-decoration:none;
  font-family:宋体;
  mso-generic-font-family:auto;
  mso-font-charset:134;
  border-top:.5pt solid #5B9BD5;
  border-bottom:2.0pt double #5B9BD5;
  mso-style-name:"汇总";}
.style46
{mso-pattern:auto none;
  background:#C6EFCE;
  color:#006100;
  font-size:11.0pt;
  font-weight:400;
  font-style:normal;
  text-decoration:none;
  font-family:宋体;
  mso-generic-font-family:auto;
  mso-font-charset:134;
  mso-style-name:"好";}
.style47
{mso-pattern:auto none;
  background:#FFEB9C;
  color:#9C6500;
  font-size:11.0pt;
  font-weight:400;
  font-style:normal;
  text-decoration:none;
  font-family:宋体;
  mso-generic-font-family:auto;
  mso-font-charset:134;
  mso-style-name:"适中";}
.style48
{mso-pattern:auto none;
  background:#D9E1F2;
  color:#000000;
  font-size:11.0pt;
  font-weight:400;
  font-style:normal;
  text-decoration:none;
  font-family:宋体;
  mso-generic-font-family:auto;
  mso-font-charset:134;
  mso-style-name:"20% - 强调文字颜色 5";}
.style49
{mso-pattern:auto none;
  background:#5B9BD5;
  color:#FFFFFF;
  font-size:11.0pt;
  font-weight:400;
  font-style:normal;
  text-decoration:none;
  font-family:宋体;
  mso-generic-font-family:auto;
  mso-font-charset:134;
  mso-style-name:"强调文字颜色 1";}
.style50
{mso-pattern:auto none;
  background:#DDEBF7;
  color:#000000;
  font-size:11.0pt;
  font-weight:400;
  font-style:normal;
  text-decoration:none;
  font-family:宋体;
  mso-generic-font-family:auto;
  mso-font-charset:134;
  mso-style-name:"20% - 强调文字颜色 1";}
.style51
{mso-pattern:auto none;
  background:#BDD7EE;
  color:#000000;
  font-size:11.0pt;
  font-weight:400;
  font-style:normal;
  text-decoration:none;
  font-family:宋体;
  mso-generic-font-family:auto;
  mso-font-charset:134;
  mso-style-name:"40% - 强调文字颜色 1";}
.style52
{mso-pattern:auto none;
  background:#FCE4D6;
  color:#000000;
  font-size:11.0pt;
  font-weight:400;
  font-style:normal;
  text-decoration:none;
  font-family:宋体;
  mso-generic-font-family:auto;
  mso-font-charset:134;
  mso-style-name:"20% - 强调文字颜色 2";}
.style53
{mso-pattern:auto none;
  background:#F8CBAD;
  color:#000000;
  font-size:11.0pt;
  font-weight:400;
  font-style:normal;
  text-decoration:none;
  font-family:宋体;
  mso-generic-font-family:auto;
  mso-font-charset:134;
  mso-style-name:"40% - 强调文字颜色 2";}
.style54
{mso-pattern:auto none;
  background:#A5A5A5;
  color:#FFFFFF;
  font-size:11.0pt;
  font-weight:400;
  font-style:normal;
  text-decoration:none;
  font-family:宋体;
  mso-generic-font-family:auto;
  mso-font-charset:134;
  mso-style-name:"强调文字颜色 3";}
.style55
{mso-pattern:auto none;
  background:#FFC000;
  color:#FFFFFF;
  font-size:11.0pt;
  font-weight:400;
  font-style:normal;
  text-decoration:none;
  font-family:宋体;
  mso-generic-font-family:auto;
  mso-font-charset:134;
  mso-style-name:"强调文字颜色 4";}
.style56
{mso-pattern:auto none;
  background:#FFF2CC;
  color:#000000;
  font-size:11.0pt;
  font-weight:400;
  font-style:normal;
  text-decoration:none;
  font-family:宋体;
  mso-generic-font-family:auto;
  mso-font-charset:134;
  mso-style-name:"20% - 强调文字颜色 4";}
.style57
{mso-pattern:auto none;
  background:#FFE699;
  color:#000000;
  font-size:11.0pt;
  font-weight:400;
  font-style:normal;
  text-decoration:none;
  font-family:宋体;
  mso-generic-font-family:auto;
  mso-font-charset:134;
  mso-style-name:"40% - 强调文字颜色 4";}
.style58
{mso-pattern:auto none;
  background:#4472C4;
  color:#FFFFFF;
  font-size:11.0pt;
  font-weight:400;
  font-style:normal;
  text-decoration:none;
  font-family:宋体;
  mso-generic-font-family:auto;
  mso-font-charset:134;
  mso-style-name:"强调文字颜色 5";}
.style59
{mso-pattern:auto none;
  background:#B4C6E7;
  color:#000000;
  font-size:11.0pt;
  font-weight:400;
  font-style:normal;
  text-decoration:none;
  font-family:宋体;
  mso-generic-font-family:auto;
  mso-font-charset:134;
  mso-style-name:"40% - 强调文字颜色 5";}
.style60
{mso-pattern:auto none;
  background:#8EA9DB;
  color:#FFFFFF;
  font-size:11.0pt;
  font-weight:400;
  font-style:normal;
  text-decoration:none;
  font-family:宋体;
  mso-generic-font-family:auto;
  mso-font-charset:134;
  mso-style-name:"60% - 强调文字颜色 5";}
.style61
{mso-pattern:auto none;
  background:#70AD47;
  color:#FFFFFF;
  font-size:11.0pt;
  font-weight:400;
  font-style:normal;
  text-decoration:none;
  font-family:宋体;
  mso-generic-font-family:auto;
  mso-font-charset:134;
  mso-style-name:"强调文字颜色 6";}
.style62
{mso-pattern:auto none;
  background:#C6E0B4;
  color:#000000;
  font-size:11.0pt;
  font-weight:400;
  font-style:normal;
  text-decoration:none;
  font-family:宋体;
  mso-generic-font-family:auto;
  mso-font-charset:134;
  mso-style-name:"40% - 强调文字颜色 6";}
.style63
{mso-pattern:auto none;
  background:#A9D08E;
  color:#FFFFFF;
  font-size:11.0pt;
  font-weight:400;
  font-style:normal;
  text-decoration:none;
  font-family:宋体;
  mso-generic-font-family:auto;
  mso-font-charset:134;
  mso-style-name:"60% - 强调文字颜色 6";}
td
{mso-style-parent:style0;
  padding-top:1px;
  padding-right:1px;
  padding-left:1px;
  mso-ignore:padding;
  mso-number-format:"General";
  text-align:general;
  vertical-align:bottom;
  white-space:nowrap;
  mso-rotate:0;
  mso-pattern:auto;
  mso-background-source:auto;
  color:windowtext;
  font-size:10.0pt;
  font-weight:400;
  font-style:normal;
  text-decoration:none;
  font-family:Arial;
  mso-generic-font-family:swiss;
  mso-font-charset:0;
  border:none;
  mso-protection:locked visible;}
.xl65
{mso-style-parent:style0;
  text-align:center;
  mso-font-charset:0;}
.xl66
{mso-style-parent:style0;
  text-align:center;
  vertical-align:middle;
  white-space:normal;
  font-size:16.0pt;
  font-weight:700;
  text-decoration:underline;
  text-underline-style:single;
  font-family:宋体;
  mso-generic-font-family:auto;
  mso-font-charset:134;}
.xl67
{mso-style-parent:style0;
  text-align:center;
  vertical-align:middle;
  font-size:16.0pt;
  font-weight:700;
  font-family:宋体;
  mso-generic-font-family:auto;
  mso-font-charset:134;}
.xl68
{mso-style-parent:style0;
  text-align:center;
  vertical-align:middle;
  font-family:宋体;
  mso-generic-font-family:auto;
  mso-font-charset:134;
  border:.5pt solid windowtext;}
.xl69
{mso-style-parent:style0;
  text-align:center;
  vertical-align:middle;
  font-family:宋体;
  mso-font-charset:0;
  border:.5pt solid windowtext;}
.xl70
{mso-style-parent:style0;
  font-family:宋体;
  mso-generic-font-family:auto;
  mso-font-charset:134;}
td{
  white-space: normal; /* 默认值 */
}
</style>