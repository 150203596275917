import { getRequest,patchRequest,deleteRequest } from "@/libs/axios";

// 管理员
let base = '/yethan/registerFeeApply'
// 减免列表
export const getRegisterFeeApplyListPage = function (params) {
    return getRequest(`${base}/listPage`, params)
}
// 删除减免
export const deleteRegisterFeeApply = function (id) {
    return deleteRequest(`${base}/${id}`)
}
// 获取详情
export const getRegisterFeeApply = function (id) {
    return getRequest(`${base}/${id}`)
}
// 获取详情
export const getRegisterFeeApplyByBmh = function (bmh) {
    return getRequest(`${base}/bmh/${bmh}`)
}
// 审核减免
export const handleRegisterFeeApply = function (id, params) {
    return patchRequest(`${base}/handle/${id}`, params)
}
//
// // 用户
// // 减免列表
// let base = '/yethan/registerFeeApply'
// export const getRegisterFeeApplyListPage = function (params) {
//     return getRequest(`${base}/listPage`, params)
// }
// // 删除减免
// export const deleteRegisterFeeApply = function (id) {
//     return deleteRequest(`${base}/${id}`)
// }
// // 获取详情
// export const getRegisterFeeApply = function (id) {
//     return getRequest(`${base}/${id}`)
// }
// // 减免申请
// export const applyRegisterFeeApply = function (bmh, params) {
//     return getRequest(`${base}/${bmh}`, params)
// }