var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex msgContent"},[_c('div',{staticClass:"flexList msgLabel w50"},[_c('div',{staticClass:"msgName"},[_vm._v("资格审核")]),(_vm.examInfo.sfxyzgjgsh)?_c('div',{staticClass:"msgVal line1 flexList",class:{
        'text-danger': _vm.form.zgshzt == 2,
        'text-success': _vm.form.zgshzt == 1,
        'text-warning': _vm.form.zgshzt == 9,
      }},[_vm._v(" "+_vm._s(_vm.form.zgshzt == 1 ? "已通过" : _vm.form.zgshzt == 2 ? "未通过，" + _vm.form.zgshsm : _vm.form.zgshzt == 9 ? "已锁定" : "未审核")+" ")]):_vm._e(),(!_vm.examInfo.sfxyzgjgsh)?_c('div',{staticClass:"msgVal line1 flexList"},[_vm._v(" 不审核 ")]):_vm._e()]),_c('div',{staticClass:"flexList msgLabel w50"},[_c('div',{staticClass:"msgName"},[_vm._v("照片审核")]),_c('div',{staticClass:"msgVal line1 flexList",class:{
        'text-danger': _vm.form.zpshzt == 2,
        'text-success': _vm.form.zpshzt == 1,
      }},[_vm._v(" "+_vm._s(_vm.form.zpshzt == 1 ? "已通过" : _vm.form.zpshzt == 2 ? "未通过" + _vm.form.zpshsm : "未审核")+" ")])]),_c('div',{staticClass:"flexList msgLabel w50"},[_c('div',{staticClass:"msgName"},[_vm._v("缴费信息")]),_c('div',{staticClass:"msgVal line1 flexList"},[_c('span',{class:{
          'text-danger': _vm.form.jfzt == 9,
          'text-success': _vm.form.jfzt == 1,
          'text-info': _vm.form.jfzt == 2,
        }},[_vm._v(" "+_vm._s(_vm.form.jfzt == 1 ? "已缴费" : _vm.form.jfzt == 0 ? "未缴费" : _vm.form.jfzt == 2 ? "免收费" : _vm.form.jfzt == 9 ? "已退费" : "未缴费")+" ")])])]),_c('div',{staticClass:"flexList msgLabel w50"},[_c('div',{staticClass:"msgName"},[_vm._v("缴费金额")]),_c('div',{staticClass:"msgVal line1 flexList"},[_vm._v(" "+_vm._s(_vm.form.yjfje / 100 + "元")+" ")])])])}
var staticRenderFns = []

export { render, staticRenderFns }