<!--  组合查询条件设置-->
<template>
  <b-modal v-model="showModal" centered title="组合查询条件设置" size="lg" title-class="font-18" hide-footer>
    <div v-for="(item, idx) in domains" :key="idx">
      <div class="flexList mb-2">
        <select class="msgValInput col-sm-2  mr-2  form-control " @change="selectItem(idx)" v-model="item['key']">
          <option value="" disabled>请选择条件</option>
          <option v-for="(obj,i) in conditionList" :value="obj.val" :key="i">{{obj.name}} </option>
        </select>
        <input class="form-control col-sm-6  msgValInput" v-if="!item.type || item.type=='input'"
          v-model="item.value" />
        <div v-if="item.type == 'date'" class="d-flex col-sm-6">
          <input type="date" class="form-control w-45 mr-2  form-select" />
          <input type="date" class="form-control w-45  form-select" />
        </div>
        <YzSelect v-if="item.type == 'select'" class="col-sm-6" :form="item" :bound="['value']" :dict="item.data">
        </YzSelect>
        <div class="col-sm-6 flexList" v-if="item.type == 'radio'">
          <div v-if="item.key=='xbm'" class="flexList">
            <!-- <yz-radio :dict="item.data" :bound="['value']" :form="item"></yz-radio> -->
            <div class="form-check mr-2 flexList"><input type="radio" name="xb" id="exampleRadios1" value="1"
                v-model="item['value']" class="form-check-input"><label for="exampleRadios1" class="form-check-label">男
              </label></div>
            <div class="form-check mr-2 flexList"><input type="radio" name="xb" id="exampleRadios2" value="2"
                v-model="item['value']" class="form-check-input"><label for="exampleRadio2"
                class="form-check-label">女</label>
            </div>
          </div>
          <div v-else class="flexList">
            <div class="form-check mr-2 flexList"><input type="radio" id="exampleRadios3" value="1"
                v-model="item['value']" class="form-check-input"><label for="exampleRadios3" class="form-check-label">有
              </label></div>
            <div class="form-check mr-2 flexList"><input type="radio" id="exampleRadios4" value="0"
                v-model="item['value']" class="form-check-input"><label for="exampleRadio4"
                class="form-check-label">无</label>
            </div>
          </div>
        </div>

        <div class="condition-icon ml-2">
          <el-button @click="addDomain" type="text" size="mini"><i class="iconfont icon-plussquare mr-2"></i>
          </el-button>
          <el-button @click.prevent="removeDomain(idx)" type="text" size="mini"><i
              class="iconfont icon-minussquare"></i></el-button>
        </div>
      </div>
    </div>
    <div class="flexList mt-3 check-modal justify-content-center"><button type="button" class="btn btn-info h30  mr-2"
        @click="search">执行查询</button>
      <button type="button" class="btn btn-secondary h30  mr-2" @click="showModal=false">取消</button>
    </div>
  </b-modal>
</template>

<script>
  import YzSelect from "@/components/form/yzSelect.vue";
  import {
    getDicts
  } from "@/api/common/dict.js";
  // import YzRadio from "@/components/form/yzRadio.vue";
  export default {
    components: {
      YzSelect,
      // YzRadio
    },
    data() {
      return {
        conditionList: [],
        domains: [{
          value: ''
        }],
        showModal: false,
        query: {},
      }
    },
    mounted() {

    },
    methods: {
      show(conditionList) {
        this.conditionList = []
        this.conditionList = conditionList;
        this.loadDict()
        this.showModal = true
      },
      refresh() {
        this.query = {}
      },
      hide() {
        this.showModal = false
      },
      // 查询
      search() {
        this.refresh()
        this.domains.forEach(item => {
          this.query[item.key] = item.value
        })
        this.$emit("search", this.query)
      },
      // 选择
      selectItem(idx) {
        let op = this.domains[idx]
        let selectObj = this.conditionList.find(k => {
          return k.val === op['key']
        })
        op['type'] = selectObj['type']
        op['data'] = selectObj['data']
        op['key'] = selectObj['val']
        this.$forceUpdate()
      },
      // 删除组合条件
      removeDomain(index) {
        if (index == 0) {
          let op = this.domains[index]
          op.key = ""
          op.data = [],
            this.$message({
              type: 'info',
              message: '只有一条数据，不能删除哦!'
            });
          return

        }
        if (index !== -1) {
          this.domains.splice(index, 1)
        }
      },
      // 新增组合条件
      addDomain() {
        this.domains.push({
          value: '',
          key: Date.now()
        });

      },
      loadDict() {
        let dicts = []
        this.conditionList.forEach(k => {
          if (k.type == "select") {
            dicts.push(k.glzdb)
          }
        })
        if (dicts.length != 0) {
          getDicts(...dicts).then((res) => {
            this.conditionList.forEach(k => {
              if (k.type == "select") {
                k['data'] = res[k.glzdb]
              }
            })
          });
        }
      },

    },

  }
</script>

<style>
  .msgValInput {
    height: 30px;
    line-height: 10px;
    padding-top: 5px;
  }
</style>
