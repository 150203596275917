import {
  getRequest,
  postRequest,
  putRequest,
  deleteRequest,
} from "@/libs/axios";


// 获取考试列表
export const getExamList = params => {
  return getRequest("/yethan/register/exam/list", params);
};
// 获取考试详细信息
export const getExamInfo = params => {
  return getRequest("/yethan/register/examRecord/" + params);
};

// 获取考试报名表
export const getExamForm = params => {
  return getRequest("/yethan/register/field/list", params, false);
};

// 获取考试大类
export const getExamFl = params => {
  return getRequest("/yethan/register/mainType/easyList", params);
};
// 获取考试二级分类
export const getExamSecondFl = params => {
  return getRequest("/yethan/register/secondType/easyList", params);
};

// 获取考试考试选科分类
export const getExamXkFl = params => {
  return getRequest("/yethan/register/classification/easyList", params);
};

// 获取考试提示的模板详情
export const getMbInfo = params => {
  return getRequest("/yethan/register/noticeTemplate/" + params, '', false);
};

// 获取报考单位
export const getUnit = params => {
  return getRequest("/yethan/register/unitsPosition/dwList", params);
};
// 获取报考职位
export const getPosition = params => {
  return getRequest("/yethan/register/examZwk/listAll/" + params, {}, false);
};

// 获取报考选科
export const getSubject = params => {
  return getRequest("/yethan/register/classification/easyList", params);
};
// 获取选科科目列表
export const getKmList = params => {
  return getRequest("/yethan/register/classification/" + params);
};


// 查询考试免试库数据列表
export const getExamFree = params => {
  return getRequest("/yethan/register/examFree/easyList", params);
};

// 查询成绩档案
export const getGradeRecord = params => {
  return getRequest("/yethan/register/cjdak/" + params);
};

// 提交免试申请
export const applyExamFree = params => {
  return postRequest("/yethan/register/examFreeUser", params);
};
// 查询免试申请列表
export const getUserExamFree = params => {
  return postRequest("/yethan/register/examFreeUser", params);
};

// 获取系统消息列表
export const getMessList = params => {
  return getRequest("/yethan/", params);
};

// 获取用户已报名考试列表或单个报名信息
export const getUserExamList = params => {
  return getRequest("/yethan/register/user/list", params);
};
// 获取用户已报名考试单个报名信息
export const getUserExamOne = (params) => {
  return getRequest("/yethan/register/user/" + params, '', false);
};
// 获取用户已报名考试单个报名
export const getUserBmk = params => {
  return postRequest("/yethan/registerUser/bmk", params);
};
// 报名提交
export const examApply = params => {
  return postRequest("/yethan/registerUser/user", params);
};
// 报名修改
export const examEdit = params => {
  return putRequest("/yethan/registerUser/user", params);
};
// 撤销报名
export const deleteApply = params => {
  return deleteRequest("/yethan/register/user/" + params);
};

//支付接口
export const examPay = params => {
  return getRequest("/yethan/pay/trade/exam/order", params);
};

// 新闻详情
export function newsDetail(newsId) {
  return getRequest("/yethan/web/news/" + newsId, '', false);
}

// 查询考试黑名单库
export function getHMDK(params) {
  return getRequest("/yethan/register/hmdk/" + params, '', false);
}

// 查询考违纪库
export function getWJK(params) {
  return getRequest("/yethan/register/wjk/" + params, '', false);
}

// 查询特殊名单库
export function userSpecial(params) {
  return getRequest("/yethan/register/userSpecial", params, false);
}

// 查询考试考区
export function getKQ(params) {
  return postRequest("/yethan/register/examArea/" + params, '', false);
}

// 查询考试选科
export function getXK(params) {
  return postRequest("/yethan/register/examSubjecct/" + params, '', false);
}


// 查询考试缴费状态
export function getjfzt(params) {
  return getRequest("/yethan/pay/trade/queryOrder", params, false);
}



// 查询考试考试科目
export function getbmkskm(params) {
  return getRequest("/yethan/register/classification/userSubject/" + params);
}

// 保存学历证明
export const saveXLZM = params => {
  return putRequest("/yethan/userInfo/saveUserInfoEducationXlzm", params);
};