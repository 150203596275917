<style type="text/css">
  @import "~@/assets/css/common.css";
  @import "~@/assets/css/enroll-form.css";
</style>
<style type="text/css" scoped>
  .modal-title /deep/ {
    text-align: center !important;
    width: 100% !important;
    padding: auto !important;
  }

  /deep/.el-select.blueBorder .el-input__inner {
    border-color: rgb(23, 118, 210);
    height: 30px
  }

  /deep/.el-transfer-panel__list,
  /deep/.el-transfer-panel__body {
    height: 400px;
  }

  /deep/.el-form-item {
    margin-bottom: 0;
  }

  .guding.top-guding {
    width: 43px;
  }
</style>
<script>
  import Layout from "@/views/layouts/main";
  import Person from "/src/views/pages/admin/enroll/person.vue";
  import EditApplyInfo from "@/components/editApplyInfo";
  import checkQuery from "@/components/table/check-query.vue"
  import {
    getRegisterUserList,
    deleteRegisterUser,
    getUnit,
    getPosition
  } from "@/api/admin/register/registerUser.js";
  import {
    getExamEasy,
    getNowExam
  } from "@/api/admin/exam/examRecord.js";
  import {
    chargeMore,
    getChargeInfo,
    quitCharge
  }
  from "@/api/pay/charge.js";
  import changeExam from '@/components/change-exam.vue'
  import CheckHeader from "@/components/check-header";
  import {
    classificationEasyList
  } from "@/api/admin/base/classification.js"
  import {
    exportBmkFields
  } from '@/api/common/public'
  /**
   * Dashboard component
   */
  export default {
    components: {
      Layout,
      EditApplyInfo,
      CheckHeader,
      Person,
      changeExam,
      checkQuery
    },
    data() {
      return {
        title: "考试报名信息审核  / ",
        subTitle: "切换报名",
        ksbmbh: "",
        ksmc: "",
        kmxzfs: 0,
        module: "BMZYB",
        checkType: '',
        checDiv: false,
        editDiv: false,
        showChargeMore: false,
        showCharge: false,
        isPhoto: "",
        deleteList: [],
        showBatch: false,
        items: [{
            text: "用户首页",
            href: "/admin"
          },
          {
            text: "控制面板",
            active: true
          }
        ],
        tabDivShow: 0,
        years: [],
        showmodal: false,
        dialogTitle: "考试报名批量缴费管理",
        addFrom: {},
        select: {},
        jffs: "",
        tableTool: {
          mzmc: 1,
          zgxlmc: 1,
          zgxwmc: 1,
          zhbyxxmc: 1,
          sfzjlxmc: 0,
          sfzjh: 1,
          zzmmmc: 0,
          hyztmc: 0,
          jkzkmc: 0,
          gjdqmc: 0,
          hjszdmc: 0,
          jg: 0,
          gatqwmc: 0,
          xyzjmc: 0,
          yddh: 0,
          dzxx: 0,
          zp: 1,
          xl: 1,
          sf: 1,
          jf: 1,
          qx: 1,
          sj: 1,
        },
        gudingFileds: [{
            label: "报考单位",
            key: "bkdwzy",
          },
          {
            label: "报考专业",
            key: "bkgwxk",
          },

          {
            label: "档案号",
            key: "dah",
          },
          {
            label: "姓名",
            key: "xm",
          },
          {
            label: "出生年月",
            key: "csrq",
          },
          {
            label: "身份证号",
            key: "sfzjh",
          },
          {
            label: "性别",
            key: "xb",
          },
          {
            label: "报考考区",
            key: "kqmc",
          },
          {
            label: "级别",
            key: "jbmc",
          },
          {
            label: "专业",
            key: "zymc",
          },
          // {
          //   key: 'zgshrzh',
          //   label: '资格审核人账号'
          // },
          {
            key: 'zgshrxm',
            label: '资格审核人姓名'
          },
          {
            key: 'bmhDec',
            label: '报名号'
          }
        ],
        allData: [{
            label: "照片",
            key: "zp",
            fixed: "right",
          },
          {
            label: "学历",
            key: "xl",
            fixed: "right",
          },
          {
            label: "收费",
            key: "sf",
            fixed: "right",
          },
          {
            label: "缴费",
            key: "jf",
            fixed: "right",
          },
          {
            label: "取消",
            key: "qx",
            fixed: "right",
          },
          {
            label: "收据",
            key: "sj",
            fixed: "right",
          },
        ],
        checkData: ['sfzjh', 'mzmc', 'zhbyxxmc', 'zp', 'xl', 'jf', 'sf', 'qx', 'sj'],
        examInfo: {},
        tableList: [],
        filedList: [],
        bmh: '',
        info: {},
        checkStatus: 0,
        checkMemo: '',
        dwList: [],
        zwList: [],
        examDiv: false,
        showItem: false,
        examList: [],
        checkList: [],
        selectObj: {},
        examParam: {
          ksnf: new Date().getFullYear(),
          ksmc: ''
        },
        fields: [{
            key: 'xm',
            label: '姓名'
          },
          {
            key: 'sfzjh',
            label: '身份证号'
          },
          {
            key: 'xb',
            label: '性别'
          },
          {
            key: 'csrq',
            label: '出生日期'
          },
          {
            key: 'mz',
            label: '民族'
          },
          {
            key: 'zzmm',
            label: '政治面貌'
          },
          {
            key: 'jg',
            label: '籍贯'
          },
          {
            key: 'csdmc',
            label: '出生地'
          },
          {
            key: 'hkszdmc',
            label: '户籍所在地'
          },
          {
            key: 'hyztmc',
            label: '婚姻状态'
          },
          {
            key: 'gjdqmc',
            label: '所在国家地区'
          },
          {
            key: 'gatqwmc',
            label: '港澳台侨'
          },
          {
            key: 'jkzkmc',
            label: '健康状态'
          },
          {
            key: 'xyzjmc',
            label: '宗教信仰'
          },
          {
            key: 'jtzz',
            label: '家庭住址'
          },
          {
            key: 'yzbm',
            label: '邮政编码'
          },
          {
            key: 'yddh',
            label: '联系电话'
          },
          {
            key: 'dzxx',
            label: '电子信箱'
          },
          {
            key: 'zgxlmc',
            label: '最高学历'
          },
          {
            key: 'zgxwmc',
            label: '最高学位'
          },
          {
            key: 'zhbyxxmc',
            label: '最后毕业学校'
          },
          {
            key: 'zhbyzy',
            label: '最后毕业专业'
          },
          {
            key: 'zhbyxxlb',
            label: '最后毕业学校类别'
          },
          {
            key: 'zhbyxxdq',
            label: '最后毕业学校国家地区'
          },
          {
            key: 'zcmc',
            label: '当前职称'
          },
          {
            key: 'hdzcsj',
            label: '获得职称时间'
          },
          {
            key: 'xgzdw',
            label: '现工作单位'
          },
          {
            key: 'szdwzw',
            label: '所在单位职务'
          }
        ],
        isIndeterminate: false,
        classificationList: [],
        flbm: "",
        deleteFrom: {},
        selectBatchItem: {},
        pageData: {
          // ksbmbh: '',
          // bkdwzym: '',
          // bkgwxkm: '',
          // keyWord: '',
          // zpshzt: '',
          // xlshzt: '',
          // zgshzt: '',
          // jfzt: '',
          pageNum: 1,
          pageSize: 20,
          total: 0
        },
        isDialog: true,
      };
    },
    methods: {
      getFileds() { // 获取表格字段
        let _this = this
        let list1 = []
        let list2 = []
        let newList = []
        _this.filedList = []
        exportBmkFields(this.ksbmbh).then(res => {
          if (res.status) {
            list1 = res.data.registerFields
            list2 = res.data.statusFields
            newList.push(...list1, ...list2)
            newList.forEach(item => {
              if (!_this.gudingFileds.filter(val => {
                  return val['key'] == item.zddm
                })[0]) {
                _this.filedList.push({
                  label: item.zdmc,
                  key: item.zddm,
                  detail: item
                })
              }
              if (!this.tableTool[item.zddm]) {
                this.tableTool[item.zddm] = 0
              }
            })
            this.allData.push(...this.filedList)
            this.getTool()
          }
        })

      },
      // 获取考生申请列表
      getList(more = {}) {
        this.pageData.ksbmbh = this.ksbmbh
        let search = {
          ...this.pageData,
          ...more
        }
        getRegisterUserList(search).then(res => {
          if (res.status) {
            this.tableList = res.data
            this.pageData.total = res.total
          }
        })
      },

      // 删除
      deleteItem(info) {
        this.$confirm(`是否确认删除考生“${info.xm}”的报名申请数据?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          deleteRegisterUser(info.bmh).then(res => {
            if (res.status) {
              this.$message({
                type: 'success',
                message: '删除成功!'
              });
              this.getList();
            }
          })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });
        });
      },

      // 显示需要的列
      getTool() {
        let check = JSON.parse(JSON.stringify(this.checkData))
        let all = this.tableTool
        for (var i in all) {
          if (check.indexOf(i) > -1) {
            this.tableTool[i] = 1
          } else {
            this.tableTool[i] = 0
          }
        }
        // 解决重叠的问题
        let tab = document.getElementById("tableDiv");
        this.$nextTick(() => {
          if (tab.scrollWidth > tab.offsetWidth) {
            this.tabDivShow = 1
          } else {
            this.tabDivShow = 0
          }
        })
        this.showmodal = false
      },
      checkShow(info, checkType) {
        if (!this.isDialog) {
          window.open(
            `/admin/checkNewWin?isPhoto=${true}&ksbmbh=${this.ksbmbh}&bmh=${info.bmh}&type=${1}&checkType=${checkType}`,
            '_blank')
        } else {
          this.bmh = info.bmh
          this.checkType = checkType
          this.checDiv = true
        }
      },
      editShow(info) {
        this.bmh = info.bmh
        this.editDiv = true
      },

      //获取切换的考试
      getLastExam() {
        getNowExam().then(res => {
          if (res.status && res.data) {
            this.ksbmbh = res.data.ksbmbh
            this.ksmc = res.data.ksmc
            this.kmxzfs = res.data.kmxzfs
            this.flbm = res.data.flbm
            this.isPhoto = res.data.sfsczp
            if (this.kabmbh == "") {
              this.getExamList()
            }
            this.getList()
            this.getFileds()
          }
        })
      },
      // 获取考试列表
      getExamList() {
        getExamEasy(this.examParam).then(res => {
          if (res.status) {
            this.ksbmbh = res.data[0].ksbmbh
            this.ksmc = res.data[0].ksmc
            this.kmxzfs = res.data[0].kmxzfs
          }
        })
      },

      handleSizeChange(val) {
        this.pageData.pageSize = val
        this.getList();
      },
      handleCurrentChange(val) {
        this.pageData.pageNum = val
        this.getList();
      },
      // 全选
      checkAll(e) {
        this.checkList = e ? this.tableList.map(item => {
            return item.bmh
          }) : [],
          this.isIndeterminate = e
      },
      // 批量收费弹窗
      handleBatch() {
        this.addFrom = {}
        this.showChargeMore = true
        this.addFrom.ksmc = this.ksmc
        this.dialogTitle = "考试报名批量缴费管理"
        this.addFrom.yjfje = "系统会根据缴费方式，自动生成"

        for (let k in this.checkList) {
          let data = this.tableList.filter(x => {
            return x.bmh == this.checkList[k]
          })[0]
          if (k < 2) {
            this.addFrom['xm'] = this.addFrom['xm'] ? (this.addFrom['xm'] + '，' + data.xm) : data.xm
            this.addFrom['sfzjh'] = this.addFrom['sfzjh'] ? (this.addFrom['sfzjh'] + '，' + data.sfzjh) : data.sfzjh
          } else {
            this.addFrom['xm'] += '等' + this.checkList.length + '人'
            this.addFrom['sfzjh'] += '....'
            return false
          }
        }
      },


      // 批量收费
      submitBatch(e) {
        let formData = JSON.parse(JSON.stringify(e))
        formData.payType = e.payType
        let bmh
        bmh = e.bmh ? e.bmh : this.checkList.join(",")
        chargeMore(bmh, formData).then(res => {
          if (res.status) {
            this.$message({
              type: "success",
              message: "批量收费成功！",
            });
            this.showChargeMore = false
            this.getList();
          }
        })
      },
      // 单个取消
      quitOne(info) {
        let data = JSON.parse(JSON.stringify(info))
        this.$confirm(`此为单个取消收费,是否确认取消对考生“${info.xm}”的收费?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          if (data.jffs == "04" || data.jffs == "06") {
            quitCharge(info.bmh).then(res => {
              if (res.status) {
                this.$message({
                  type: 'success',
                  message: '取消收费成功!'
                });
                this.getList();
              }
            })
          } else {
            this.$message({
              type: 'warning',
              message: '非人工收费不可取消!'
            });
          }
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消操作'
          });
        });
      },

      // 批量删除
      quitBatch(info) {
        let bmh
        bmh = info.bmh ? info.bmh : this.checkList.join(",")
        let newList = []
        newList = this.tableList.filter(k => {
          return this.checkList.indexOf(k.bmh) > -1 && (k.jffs != "04" && k.jffs != "06")
        })
        if (newList.length) {
          this.$message({
            type: 'warning',
            message: '勾选数据包含在线支付记录，已自动过滤在线记录!'
          });
          newList = this.tableList.filter(k => {
            return this.checkList.indexOf(k.bmh) > -1 && (k.jffs == "04" || k.jffs == "06")
          })
          bmh = newList.map(x => x.bmh).join(",")
        }

        quitCharge(bmh).then(res => {
          console.log(res)
          if (res.status) {

            this.$message({
              type: 'success',
              message: '取消收费成功!'
            });
            this.showBatch = false
            this.getList();

          }
        })
      },
      quit() {
        if (this.checkList.length) {
          this.showBatch = true
        } else {
          this.$message({
            type: "warning",
            message: "请选择需要批量的数据！",
          });
        }
      },

      // 单个编辑收费记录弹窗
      editChargeRecord(obj) {
        this.showChargeMore = true
        this.dialogTitle = "考试报名缴费管理"
        this.addFrom = JSON.parse(JSON.stringify(obj))
        this.addFrom.bmh = obj.bmh
        this.getRecord(obj.bmh)
      },
      // 记录摘要
      getRecord(bmh) {
        getChargeInfo(bmh).then(res => {
          if (res.status) {
            console.log(res)
            this.addFrom.yjfje = res.data.yjfje / 100
          }
        })
      },
      isDialogChange(e) {
        this.isDialog = e
      },
    },
    mounted() {
      this.getLastExam()
      this.getTool()

    },
    computed: {
      fixedData() {
        let allData = JSON.parse(JSON.stringify(this.allData))
        let tableTool = JSON.parse(JSON.stringify(this.tableTool))
        return allData.filter(k => {
          return k.fixed == 'right' && tableTool[k.key]
        })
      }
    }
  }
</script>

<template>
  <Layout>
    <CheckHeader :title="title" :title2="ksmc" :subTitle="subTitle" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card" style="min-height: 600px;">
          <div class="card-body">
            <checkQuery :kmxzfs="kmxzfs" v-model="pageData" @getList="getList" @isDialogChange="isDialogChange"
              :showDialog="false" :ksbmbh="ksbmbh" :isPay="true">
            </checkQuery>

            <div class="d-flex justify-content-between mb-3">

              <div class="flexList">
                <label class="mr-2">批量操作：</label>
                <b-button variant="outline-info" class="flexList mr-2 condition h30" @click="handleBatch()"><i
                    class="iconfont icon-Finance mr-2"></i>批量收费</b-button>
                <b-button variant="outline-secondary" class="flexList mr-2  h30" @click="quit"><i
                    class="iconfont icon-ze-clear mr-2"></i>取消收费</b-button>
                <b-button variant="outline-success" class="flexList mr-2 h30"><i
                    class="iconfont icon-antOutline-printer mr-2"></i>打印收据</b-button>
              </div>

            </div>
            <div style="position: relative;">
              <div class="table-responsive border" id="tableDiv" style="max-width: 100%; min-height: 200px;">
                <el-checkbox-group v-model="checkList">
                  <table class="table light-table table-hover table-bordered table-scroll " id="tabDiv2">
                    <thead class="thead-light">
                      <tr>
                        <th style="width: 55px">
                          <el-checkbox :indeterminate="isIndeterminate" @change="checkAll" class="mr-1"></el-checkbox>序号
                        </th>
                        <th style="width: 120px;" v-if="kmxzfs == 2">报考单位</th>
                        <th style="width: 200px;">{{ kmxzfs == 1?'报考专业':'报考职位'}}</th>
                        <th style="width: 160px" v-if="kmxzfs == 1">档案号</th>
                        <th style="width: 80px">姓名</th>
                        <!-- <th style="width: 85px">出生年月</th> -->
                        <th style="width: 45px">性别</th>
                        <th style="width: 110px" v-if="kmxzfs == 1">报考考区</th>
                        <th style="width: 60px" v-if="kmxzfs == 1">级别</th>
                        <th style="width: 180px" v-if="kmxzfs == 1">专业</th>
                        <th style="width: 120px">报名号</th>
                        <!-- <th style="width: 180px">资格审核人账号</th> -->
                        <th style="width: 180px">资格审核人姓名</th>
                        <th style="width: 110px" v-for="obj in filedList" :key="obj.index" v-if="tableTool[obj.key]">
                          {{obj.label}}
                        </th>
                        <th style="width: 43px" class="guding " v-if="tableTool.xx">信息 </th>
                        <th style="width: 43px" class="guding " v-if="tableTool.zp">照片 </th>
                        <th style="width: 43px" class="guding " v-if="tableTool.xl">学历 </th>
                        <th style="width: 43px" class="guding " v-if="tableTool.jf">缴费 </th>
                        <th style="width: 43px" class="guding " v-if="tableTool.sf">收费 </th>
                        <th style="width: 43px" class="guding " v-if="tableTool.qx">取消 </th>
                        <th style="width: 43px" class="guding " v-if="tableTool.sj">收据 </th>
                      </tr>

                    </thead>
                    <tbody>

                      <tr v-for="(item,index) in tableList" :key="index">
                        <td>
                          <el-checkbox :label="item.bmh">{{index+1+(pageData.pageNum-1)*pageData.pageSize}}
                          </el-checkbox>
                        </td>
                        <td v-if="kmxzfs == 2">{{ item.bkdwzy }}</td>
                        <td>
                          <div class="line1" :title="item.bkgwxk">{{ item.bkgwxk }}</div>
                        </td>
                        <td v-if="kmxzfs == 1">{{item.dah}}</td>
                        <td>
                          <el-button type="text" size="mini" class="font-blue h30" @click="checkShow(item, '')">
                            {{ item.xm }}
                          </el-button>
                        </td>
                        <!-- <td>{{ item.csrq }}</td> -->
                        <td>{{ item.xb }}</td>
                        <td v-if="kmxzfs == 1">{{item.kqmc}}</td>
                        <td v-if="kmxzfs == 1">{{item.bkjbmc}}</td>
                        <td v-if="kmxzfs == 1">{{item.bkdwzy}}</td>
                        <td>{{item.bmhDec}}</td>
                        <!-- <td>{{item.zgshrzh}}</td> -->
                        <td>{{item.zgshrxm}}</td>
                        <td v-for="obj in filedList" :key="obj.index" v-if="tableTool[obj.key]">
                          <div class="line1" :title="item[obj.key]">{{ item[obj.key] }}</div>
                        </td>
                        <td v-if="tableTool.zp" class="tab-icon light-yellow-bg guding top-guding">
                          <span role="button" @click="checkShow(item, 'photo')" title="照片审核">
                            <i v-if="item.zpshzt == 1" class="iconfont icon-a-ze-checkedCopy2 text-success "></i>
                            <i v-else-if="item.zpshzt == 2" class="iconfont icon-ze-clear text-danger "></i>
                            <i v-else class="iconfont icon-icon_minus-circled "></i>
                          </span>
                        </td>
                        <td v-if="tableTool.xl" class="tab-icon light-yellow-bg guding top-guding">
                          <span role="button" @click="checkShow(item, 'education')" title="学历审核">
                            <i v-if="item.xlshzt == 1" class="iconfont icon-a-ze-checkedCopy2 text-success "></i>
                            <i v-else-if="item.xlshzt == 2" class="iconfont icon-ze-clear text-danger "></i>
                            <i v-else class="iconfont icon-icon_minus-circled "></i>
                          </span>
                        </td>
                        <td v-if="tableTool.zg" class="tab-icon light-yellow-bg guding top-guding">
                          <span role="button" @click="checkShow(item, 'quality')" title="资格审核">
                            <i v-if="item.zgshzt == 1" class="iconfont icon-a-ze-checkedCopy2 text-success "></i>
                            <i v-else-if="item.zgshzt == 2" class="iconfont icon-ze-clear text-danger "></i>
                            <i v-else-if="item.zgshzt == 9" class="iconfont el-icon-lock text-danger "></i>
                            <i v-else class="iconfont icon-icon_minus-circled "></i>
                          </span>
                        </td>
                        <td v-if="tableTool.jf" class="tab-icon  light-yellow-bg guding top-guding">
                          <i v-if="item.jfzt == 1" class="iconfont icon-a-ze-checkedCopy2 text-success "></i>
                          <i v-else-if="item.jfzt == 2" class="iconfont icon-ze-clear text-danger "></i>
                          <i v-else class="iconfont icon-icon_minus-circled "></i>
                        </td>
                        <td class="tab-icon light-pink-bg" v-if="tableTool.sf"><i
                            class="iconfont icon-Finance  text-info" @click="editChargeRecord(item)"></i></td>
                        <td class="tab-icon light-pink-bg" v-if="tableTool.qx" @click="quitOne(item)"><i
                            class="iconfont icon-ze-clear "></i>
                        </td>
                        <td class="tab-icon light-blue-bg" v-if="tableTool.sj"><i
                            class="iconfont icon-antOutline-printer  text-info"></i></td>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </el-checkbox-group>
                <div class="fixedRight" :style="[{width:fixedData.length*43+'px'}]" v-if="tabDivShow">
                  <table class="table light-table table-hover table-bordered table-scroll tableCopy" id="tabDiv2">
                    <thead class="thead-light">
                      <tr>
                        <th style="width: 55px">
                          <el-checkbox :indeterminate="isIndeterminate" @change="checkAll" class="mr-1"></el-checkbox>序号
                        </th>
                        <th style="width: 120px;" v-if="kmxzfs == 2">报考单位</th>
                        <th style="width: 200px;">{{ kmxzfs == 1?'报考专业':'报考职位'}}</th>
                        <th style="width: 160px" v-if="kmxzfs == 1">档案号</th>
                        <th style="width: 80px">姓名</th>
                        <!-- <th style="width: 85px">出生年月</th> -->
                        <th style="width: 45px">性别</th>
                        <th style="width: 110px" v-if="kmxzfs == 1">报考考区</th>
                        <th style="width: 60px" v-if="kmxzfs == 1">级别</th>
                        <th style="width: 180px" v-if="kmxzfs == 1">专业</th>
                        <th style="width: 120px">报名号</th>
                        <!-- <th style="width: 180px">资格审核人账号</th> -->
                        <th style="width: 180px">资格审核人姓名</th>
                        <th style="width: 110px" v-for="obj in filedList" :key="obj.index" v-if="tableTool[obj.key]">
                          {{obj.label}}
                        </th>
                        <th style="width: 43px" class="guding " v-if="tableTool.xx">信息 </th>
                        <th style="width: 43px" class="guding " v-if="tableTool.zp">照片 </th>
                        <th style="width: 43px" class="guding " v-if="tableTool.xl">学历 </th>
                        <th style="width: 43px" class="guding " v-if="tableTool.jf">缴费 </th>
                        <th style="width: 43px" class="guding " v-if="tableTool.sf">收费 </th>
                        <th style="width: 43px" class="guding " v-if="tableTool.qx">取消 </th>
                        <th style="width: 43px" class="guding " v-if="tableTool.sj">收据 </th>
                      </tr>

                    </thead>
                    <tbody>

                      <tr v-for="(item,index) in tableList" :key="index">
                        <td>
                          <el-checkbox :label="item.bmh">{{index+1+(pageData.pageNum-1)*pageData.pageSize}}
                          </el-checkbox>
                        </td>
                        <td v-if="kmxzfs == 2">{{ item.bkdwzy }}</td>
                        <td>
                          <div class="line1" :title="item.bkgwxk">{{ item.bkgwxk }}</div>
                        </td>
                        <td v-if="kmxzfs == 1">{{item.dah}}</td>
                        <td>
                          <el-button type="text" size="mini" class="font-blue h30" @click="checkShow(item, '')">
                            {{ item.xm }}
                          </el-button>
                        </td>
                        <!-- <td>{{ item.csrq }}</td> -->
                        <td>{{ item.xb }}</td>
                        <td v-if="kmxzfs == 1">{{item.kqmc}}</td>
                        <td v-if="kmxzfs == 1">{{item.bkjbmc}}</td>
                        <td v-if="kmxzfs == 1">{{item.bkdwzy}}</td>
                        <td>{{item.bmhDec}}</td>
                        <!-- <td>{{item.zgshrzh}}</td> -->
                        <td>{{item.zgshrxm}}</td>
                        <td v-for="obj in filedList" :key="obj.index" v-if="tableTool[obj.key]">
                          <div class="line1" :title="item[obj.key]">{{ item[obj.key] }}</div>
                        </td>
                        <td v-if="tableTool.zp" class="tab-icon light-yellow-bg guding top-guding">
                          <span role="button" @click="checkShow(item, 'photo')" title="照片审核">
                            <i v-if="item.zpshzt == 1" class="iconfont icon-a-ze-checkedCopy2 text-success "></i>
                            <i v-else-if="item.zpshzt == 2" class="iconfont icon-ze-clear text-danger "></i>
                            <i v-else class="iconfont icon-icon_minus-circled "></i>
                          </span>
                        </td>
                        <td v-if="tableTool.xl" class="tab-icon light-yellow-bg guding top-guding">
                          <span role="button" @click="checkShow(item, 'education')" title="学历审核">
                            <i v-if="item.xlshzt == 1" class="iconfont icon-a-ze-checkedCopy2 text-success "></i>
                            <i v-else-if="item.xlshzt == 2" class="iconfont icon-ze-clear text-danger "></i>
                            <i v-else class="iconfont icon-icon_minus-circled "></i>
                          </span>
                        </td>
                        <td v-if="tableTool.zg" class="tab-icon light-yellow-bg guding top-guding">
                          <span role="button" @click="checkShow(item, 'quality')" title="资格审核">
                            <i v-if="item.zgshzt == 1" class="iconfont icon-a-ze-checkedCopy2 text-success "></i>
                            <i v-else-if="item.zgshzt == 2" class="iconfont icon-ze-clear text-danger "></i>
                            <i v-else-if="item.zgshzt == 9" class="iconfont el-icon-lock text-danger "></i>
                            <i v-else class="iconfont icon-icon_minus-circled "></i>
                          </span>
                        </td>
                        <td v-if="tableTool.jf" class="tab-icon  light-yellow-bg guding top-guding">
                          <i v-if="item.jfzt == 1" class="iconfont icon-a-ze-checkedCopy2 text-success "></i>
                          <i v-else-if="item.jfzt == 2" class="iconfont icon-ze-clear text-danger "></i>
                          <i v-else class="iconfont icon-icon_minus-circled "></i>
                        </td>
                        <td class="tab-icon light-pink-bg" v-if="tableTool.sf"><i
                            class="iconfont icon-Finance  text-info" @click="editChargeRecord(item)"></i></td>
                        <td class="tab-icon light-pink-bg" v-if="tableTool.qx" @click="quitOne(item)"><i
                            class="iconfont icon-ze-clear "></i>
                        </td>
                        <td class="tab-icon light-blue-bg" v-if="tableTool.sj"><i
                            class="iconfont icon-antOutline-printer  text-info"></i></td>
                        </td>
                      </tr>
                    </tbody>
                  </table>

                </div>
              </div>
            </div>
            <div class="float-right d-flex">
              <el-pagination @size-change="handleSizeChange" background @current-change="handleCurrentChange"
                :current-page.sync="pageData.pageNum" :page-size="pageData.pageSize"
                layout="total, sizes,jumper, prev, pager, next" :total="pageData.total">
              </el-pagination>
            </div>

          </div>
        </div>
      </div>


    </div>
    <!-- 弹窗开始 -->
    <!-- 表格列数自定义 -->
    <b-modal id="tableSet" v-model="showmodal" centered title="表格自定义" title-class="font-18" size="lg" hide-footer>
      <div style="padding-left: 85px;">
        <el-transfer v-model="checkData" :data="allData" :titles="['所有列', '需要显示的列']"></el-transfer>
      </div>
      <div class="text-center mt-3"> <button type="button" class="btn btn-info mr-2 w-sm" @click="getTool">确定</button>
        <button type="button" class="btn btn-secondary w-sm" @click="showmodal = false"> 取消</button>
      </div>
    </b-modal>
    <!-- 报名审核弹出 -->
    <b-modal id="person" v-model="checDiv" :title="ksmc" size="xl" title-class="font-18" hide-footer>
      <div class="flexList ">
        <div class="blue-font mr-2">考生信息</div>
      </div>
      <Person :ksbmbh="ksbmbh" :bmh="bmh" :isPhoto="isPhoto" :checkType="checkType" />

    </b-modal>
    <!-- 编辑审核弹出 -->
    <b-modal id="person" v-model="editDiv" :title="ksmc" size="xl" title-class="font-18" hide-footer>
      <div class="flexList ">
        <div class="blue-font mr-2">考生信息</div>
      </div>
      <editApply-info :ksbmbh="ksbmbh" :bmh="bmh" :checkType="checkType" />

    </b-modal>

    <!-- 批量收费 -->
    <b-modal v-model="showChargeMore" centered :title="dialogTitle" size="lg" title-class="font-18" hide-footer>
      <div class="flexList fs-xs w-100">
        <div class=" msgContent flexList w-100" style="flex-wrap: wrap;">
          <div class="flexList w-100 msgLabel">
            <div class="msgName label-required">报名信息</div>
            <div class="msgVal flexList">
              <div class="line1">
                {{addFrom.ksmc}}
              </div>
            </div>
          </div>
          <div class="flexList w-100 msgLabel">
            <div class="msgName label-required">考生姓名</div>
            <div class="msgVal flexList">
              <div class="line1">
                {{addFrom.xm}}
              </div>
            </div>
          </div>
          <div class="flexList w-100 msgLabel">
            <div class="msgName label-required">身份证号</div>
            <div class="msgVal flexList">
              <div class="line1">{{addFrom.sfzjh}}</div>
            </div>
          </div>
          <div class="flexList w-100 msgLabel">
            <div class="msgName label-required">应收金额（元）</div>
            <div class="msgVal flexList">
              <div class="line1">{{addFrom.yjfje}}</div>
            </div>
          </div>
          <div class="flexList w-100 msgLabel">
            <div class="msgName label-required">缴费方式</div>
            <div class="msgVal flexList">
              <div class="form-check mr-2 flexList"><input type="radio" name="qyzt" id="exampleRadios1"
                  v-model="addFrom.payType" value="1" class="form-check-input mb-1"><label for="exampleRadios1"
                  class="form-check-label">现金缴费
                </label></div>
              <div class="form-check mr-2 flexList"><input type="radio" name="qyzt" v-model="addFrom.payType"
                  id="exampleRadios2" value="2" class="form-check-input mb-1"><label for="exampleRadios2"
                  class="form-check-label">单位转账
                </label></div>
            </div>
          </div>
        </div>
      </div>
      <div class=" mt-3  text-center"><button type="button" class="btn btn-info h30 w-md mr-3"
          @click="submitBatch(addFrom)">确定</button>
        <button type="button" class="btn btn-secondary h30  w-md" @click="showChargeMore = false">取消</button>
      </div>
    </b-modal>
    <!-- 批量取消收费 -->
    <b-modal id="modal-center" v-model="showBatch" centered title="费用批量取消" title-class="font-18" hide-footer>
      <div class="red-font">您已批量选中数据 {{checkList.length}} 条，请选择操作</div>
      <div class="flexList mt-3 check-modal"><button type="button" class="btn btn-info h30  mr-2"
          @click="quitBatch">批量取消收费</button>
        <button type="button" class="btn btn-secondary h30  mr-2" @click="showBatch=false">关闭窗口</button>
      </div>
    </b-modal>
    <!-- 切换考试 -->
    <changeExam></changeExam>

  </Layout>
</template>
