// 考生报名信息管理
import { getRequest, postRequest, patchRequest, putRequest, deleteRequest, downloadRequest} from "@/libs/axios";

// 考生报名摘要信息列表
export const getRegisterUserList = params => {
  return getRequest("/yethan/registerUser/listPage", params);
};

// 报名考生摘要信息详情
export function getRegisterUserInfo(bmh){
  return getRequest("/yethan/registerUser/"+bmh);
}

// 修改
export const editRegisterUser = params => {
  return putRequest("/yethan/registerUser/save", params);
};

// 删除
export function deleteRegisterUser(bmh){
  return deleteRequest("/yethan/registerUser/"+bmh);
}

// 报名考生摘要信息导出excel
export function excelRegisterUser(params={}) {
  return downloadRequest("/yethan/registerUser/exportExcel", params);
}

// 审核考生报名信息
export function checkRegisterUser(params={}) {
  return patchRequest("/yethan/registerUser/checkRegisterUser/" + params.checkType, params);
}

// 获取报考单位
export const getUnit = params => {
  return getRequest("/yethan/register/unitsPosition/dwList", params);
};

// 获取报考职位
export const getPosition = params => {
  return getRequest("/yethan/register/unitsPosition/zwList", params);
};


// 查询考生考试科目
export function registerUserSubject(params={}) {
  return postRequest("/yethan/registerUser/registerUserSubject", params);
}


// 获取考生真实电话
export function realPhone(bmh, params={}) {
  return getRequest(`/yethan/registerUser/real/phone/${bmh}`, params);
}