<style type="text/css">
@import "~@/assets/css/common.css";
@import "~@/assets/css/admin.css";

@import "~@/assets/css/enroll-form.css";
</style>
<style scoped>
.w20 {
  width: 20%;
}

/* .w20:last-child {
  width: 19%;
} */
.w33 {
  width: 33.33%;
}

/* .w33:last-child {
  width: 34%;
} */
.w50 {
  width: 50%;
}

.w66 {
  width: 66.66%;
}

.w100 {
  width: 100%;
}

.form-select {
  padding: 3px;
}

.msgContent {
  border: unset;
}

.msgName {
  /* border: unset; */
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 15px;
}

.msgLabel {
  /* border: 1px solid #dfdfdf; */
}

.msgLabel.msgtextarea .msgVal {
  display: flex !important;
}

.msgVal {
  /* border-top: unset; */
  padding: 0 10px;
}

.upload-demo {
  display: flex;
  align-items: center;
}

/deep/.el-select.blueBorder .el-input__inner {
  border-color: rgb(23, 118, 210);
}

.guding-img {
  width: 33.33%;
  display: flex;
  align-items: center;
  justify-content: center;
  /*border: 1px solid #dfdfdf;*/
}

.border-r-b {
  border-right: 1px solid #dfdfdf;
  border-bottom: 1px solid #dfdfdf;
}
</style>
<style type="text/css" scoped>
/deep/ .selectShow [readOnly] {
  border: none;
}

.selectShow :disabled {
  border: none;
}

.custom-file-label::after {
  display: none;
}

.el-form-item {
  margin-bottom: unset;
  width: 100%;
}

.msgVal {
  padding-left: 10px;
  padding-right: 10px;
}

.msgtextarea .msgVal {
  display: block;
}

.msgVal,
.msgName {
  border: 1px solid #dfdfdf;
  margin-bottom: -1px;
  margin-right: -1px;
  /*border-left: 1px solid #dfdfdf;*/
  overflow: hidden;
  text-overflow: ellipsis;
  height: auto;
  line-height: 15px;
}

/deep/.el-input__inner {
  background-color: #fff9eb;
  width: 100%;
  height: 30px;
  background: unset;
}

/deep/ .el-textarea__inner {
  background-color: #fff9eb;
}

/deep/.el-form-item__content {
  line-height: inherit;
}

.el-form-item--small .el-form-item__error {
  margin-top: 2px;
}

.el-date-editor.el-input,
.el-date-editor.el-input__inner {
  width: 100%;
}

/deep/.el-form-item__error {
  position: absolute !important;
  top: 20%;
  left: 60%;
  text-align: center;
  width: 120px;
  height: 21px;
  border-radius: 2px;
  font-size: 12px;
  line-height: 21px;
  z-index: 99;
}

.el-radio {
  margin-bottom: 0;
}

.custom-file-label::after {
  display: none;
}

.msgLabel{
  min-height: 45px;
  line-height: normal;
  height: auto;
}

.canEdit {
  border: 1px solid red;
}

.errorzd {
  border: 1px solid red;
  margin-bottom: 0;
}

@media screen and (min-width: 1200px) {
  .msgName {
    min-width: 150px;
    width: 150px;
  }
}

/deep/ .el-input__icon {
  line-height: 30px;
}
</style>
<script>
import draggable from "vuedraggable";
import yzFormItem from "@/components/form/yzFormItem.vue";
import $ from "jquery";
import { getTemplateListByKsbmh } from "@/api/admin/exam/examTemplate.js";
import { getExamDetails } from "@/api/admin/exam/examRecord.js";
import { getUnit } from "@/api/admin/register/registerUser.js";
import { getPosition } from "@/api/examination_user.js";
import { classificationEasyList } from "@/api/admin/base/classification.js";
import { chooseInfoMethods } from "@/state/helpers";
import { getUserEdu } from "@/api/userInfo_user.js";
import { checkFile } from "@/libs/common_user.js";
import { covertToForm } from "@/api/common/draggable.js";
import { uploadFiles } from "@/api/index_user";
import { realPhone } from "@/api/admin/register/registerUser";
import { getStore, setStore } from "../../libs/storage";
import { getNowExamHandle } from "../../api/admin/exam/examRecord";
/**
 * 报名信息提交
 */
export default {
  components: {
    draggable,
    yzFormItem,
  },
  model: {
    prop: "form",
    event: "input",
  },
  props: {
    ksbmbh: {
      type: String,
    },
    bmh: {
      type: String,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    printflag: {
      type: Boolean,
      default: false,
    },
    form: Object,
    kxgList: {
      type: Array,
      default: () => {
        return [];
      },
    },
    errorList: {
      type: Array,
      default: () => {
        return [];
      },
    },
    showType:{
      type:String,
      default:''
    },
    isShowYJ:{
      type:Boolean,
      require:false,
      default:false
    },
    print:{
      type:Boolean,
      require:false,
      default:false
    }
  },
  data() {
    return {
      headFlag: true,
      examInfo: {},
      eduList: [],
      xkList: [],
      dwList: [],
      list1: [],
      list2: [],
      list3: [],
      img: "",
      gdzd: ["bkdwzym", "bkgwxkm", "xm", "xbm", "sfzjlxm", "sfzjh"],
      addList: [
        {
          sfbt: true,
          zddm: "xm",
          zdmc: "姓名",
          data: "",
          zdfl: "1",
          txlx: "input",
          zdbl: "50",
          readOnly: true,
          json: "xm",
        },
        {
          sfbt: true,
          glzdb: false,
          zddm: "xbm",
          zdmc: "性别",
          zdbmc: "dict_xb",
          zdkxz: "1|2,男|女",
          zdcd: 2,
          zdfl: "1",
          txlx: "radio",
          zdbl: 50,
          readOnly: true,
          json: "xbm,xb",
        },
        {
          zddm: "sfzjlxm",
          zdbmc: "dict_sfzjlx",
          glzdb: true,
          zdcd: 30,
          zdfl: "1",
          sfbt: true,
          zdmc: "证件类型",
          txlx: "select",
          zdbl: 50,
          readOnly: true,
          json: "sfzjlxm,sfzjlxmc",
        },
        {
          sfbt: true,
          zddm: "sfzjh",
          zdmc: "证件号码",
          data: "",
          zdfl: "1",
          txlx: "input",
          zdbl: "50",
          readOnly: true,
          json: "sfzjh",
        },

        {
          zddm: "csrq",
          zdbmc: "",
          glzdb: false,
          zdcd: 10,
          zdfl: "1",
          sfbt: true,
          zdmc: "出生年月",
          txlx: "date",
          zdbl: 50,
          json: "csrq",
        },
        {
          glzdb: true,
          sfbt: true,
          txlx: "select",
          zdbl: 50,
          zdcd: 30,
          zdbmc: "dict_mz",
          zddm: "mzm",
          zdfl: "1",
          zdmc: "民族",
          json: "mzm,mzmc",
        },
      ],
      astrict: {
        gznxzm: {},
      },
      addxk: [
        {
          sfbt: true,
          zddm: "sfty",
          zdmc: "告知承诺制",
          zdfl: "1",
          data: [],
          txlx: "gzcn",
          zdbl: "100",
          // readOnly: true,
        },
        // {
        //   sfbt: true,
        //   zddm: "bkjbmc",
        //   json: "bkjbmc",
        //   zdmc: "报考级别",
        //   data: [],
        //   zdfl: "1",
        //   txlx: "input",
        //   zdbl: "50",
        //   readOnly: true,
        // },
      ],
      adddw: [
        {
          sfbt: true,
          zddm: "bkdwzym",
          json: "bkdwzym,bkdwzy",
          zdmc: "报考单位",
          data: [],
          zdfl: "1",
          txlx: "select",
          zdbl: "50",
          readOnly: true,
        },
        {
          sfbt: true,
          zddm: "bkgwxkm",
          json: "bkgwxkm,bkgwxk",
          zdmc: "报考岗位",
          data: [],
          zdfl: "1",
          txlx: "select",
          zdbl: "50",
          readOnly: true,
        },
      ],
      xlDict: {
        zgxlm: "xlm", //最高学历码
        zgxlmc: "xlmc", //最高学历名称
        zgxwm: "hdxwmc", //最高学位码
        zgxwmc: "hdxwm", //最高学位名称
        zhbyxxmc: "byyxxhdw", //最后毕业学校
        zhbyzy: "sxzymc", //最后毕业专业
        zgxlxxfs: "xxfsm", //最高学历学习方式
        zgxlzfh: "xlzsh", //最高学历证书号
        // zgxwxxfs: "",//最高学位学习方式
        zgxwzsh: "xwzsh", //最高学位证书号
        zhbysj: "jsxyny", //最后毕业时间
        // zhbyxxlb: "",//最后毕业学校类别
        zhbyxxdqm: "gjdqm", //最后毕业学校国家地区码
        zhbyxxdq: "gjdqmc", //最后毕业学校国家地区
        xlshzt: "xlshzt", //学历审核状态
        // xlshsm: "xlshbz", //学历审核状态说明
        zgxlsid: "sid",
        xlzm: "xlzm",
      },
      dialogVisible: false,
      zwList: [],
      canShowPhone: true,
    };
  },
  methods: {
    ...chooseInfoMethods,
    open() {
      if (this.inspect()) {
        this.dialogVisible = true;
        return;
      }
    },
    save() {
      this.$emit("save");
    },
    submitFrom() {
      this.$emit("submit");
    },
    getForm() {},
    //检验报名表单
    inspect() {
      let isSubmit = true;
      let data = covertToForm(this.list1);
      for (let index = 0; index < data.length; index++) {
        if (data[index].must) {
          if (
            typeof data[index].bound != "string" &&
            (this.form[data[index].bound[0]] == null ||
              this.form[data[index].bound[0]].length == 0)
          ) {
            isSubmit = false;
          } else if (
            typeof data[index].bound == "string" &&
            (this.form[data[index].bound] == null ||
              this.form[data[index].bound].length == 0)
          ) {
            isSubmit = false;
          }
        }
        if (!isSubmit) {
          this.$alert(data[index].name + "不能为空", "提示", {
            confirmButtonText: "确定",
          });
          return false;
        }
        if (data[index].bound == "zgxlm") {
          let data = this.eduList.filter((v) => {
            return v.sid == this.form[data.bound[index]];
          })[0];
          if (data && data.xlshzt != 1 && !data.xlzm) {
            this.$confirm(
              "学历信息未通过, 需要提交学历证明材料，请前往学历信息完善页面提交?",
              "提示",
              {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
              }
            )
              .then(() => {
                this.$router.push("/personalEducation");
              })
              .catch(() => {
                return false;
              });
          }
        }
      }
      if (
        !this.form.sfty &&
        !this.form.gznxzm &&
        this.form.sfty !== null &&
        this.examInfo.sfgzcn
      ) {
        this.$alert("不采用告知承诺制，必须上传工作年限证明", "提示", {
          confirmButtonText: "确定",
        });
        return false;
      }

      if (isSubmit) {
        return true;
      }
    },
    //获取考试详情
    getExamInfo() {
      getExamDetails(this.ksbmbh).then((res) => {
        if (res.status) {
          this.examInfo = Object.assign({},res.data);
          if(this.printflag) {
            this.getFormList();
          }
        }
      });
    },
    // 获取选科
    getXKList() {
      return new Promise((re) => {
        classificationEasyList({
          flbm: this.examInfo.flbm,
        }).then((res) => {
          if (res.status) {
            let xkList = [];
            let data = res.data;
            data.forEach((item) => {
              xkList.push({
                name: item.xkmc + "【" + this.form.bkjbmc + "】",
                value: item.xkdm,
                detail: item,
              });
            });
            this.xkList = xkList;
            re(xkList);
          }
        });
      });
    },
    //获取单位
    getDwList() {
      return new Promise((re) => {
        getUnit({
          ksbmbh: this.ksbmbh,
        }).then((res) => {
          if (res.status) {
            let data = res.data;
            data.forEach((item) => {
              this.dwList.push({
                name: item.dwmc,
                value: item.dwdm,
                detail: item,
              });
            });
            // this.list2[0].data = this.dwList;
            re(this.dwList);
          }
        });
      });
    },
    //获取职位
    getZwList() {
      return new Promise((re) => {
        getPosition(this.ksbmbh).then((res) => {
          if (res.status) {
            let data = res.data;
            this.zwList = [];
            this.dwList = [];
            let dwobj = {};
            data.forEach((item, index) => {
              item.name = item.zwmc + "(" + item.zwbm + ")";
              item.value = item.zwbm;
              if (dwobj[item.bkdwdm]) {
                dwobj[item.bkdwdm].zwlist.push(item);
              } else {
                dwobj[item.bkdwdm] = {
                  name: item.bkdw,
                  value: item.bkdwdm,
                  zwlist: [item],
                };
              }
            });
            this.dwList = Object.values(dwobj);
            if(this.form.bkdwzym) {
              this.zwList = this.dwList.filter((v) => {
                return v.value == this.form.bkdwzym;
              })[0].zwlist;
              this.list1[1].data.push(...this.zwList);
              this.list1[0].data.push(...this.dwList);
            }
            re();
          }
        });
      });
    },
    listDiff() {
      this.list2 = [];
      this.list3 = [];
      //判断可修改字段
      this.list1.forEach((item, index) => {
        //特殊名单中的信息不能修改
        // if (this.specialData.json) {
        //   let obj = JSON.parse(this.specialData.json) || "";
        //   if (obj && obj[item.zddm]) {
        //     item.readOnly = true;
        //     if (item.glzdb || item.zdkxz) {
        //       item.txlx = "input";
        //       item.zddm = item.xszd;
        //     }
        //   }
        // }

        if (this.xlDict[item.zddm] && item.zddm != "zgxlm") {
          item.readOnly = true;
        }
        item.error = false;
        if (
          this.kxgList.filter((v) => {
            return v == item.zddm && !this.xlDict[v];
          })[0]
        ) {
          item.canEdit = true;
        } else if (
          this.kxgList.filter((v) => {
            return v == item.zddm && this.xlDict[v];
          })[0]
        ) {
          for (const val of this.list1) {
            if (val.zddm == "zgxlm") {
              val.canEdit = true;
            }
          }
        }
        if (
          this.errorList.filter((v) => {
            return v == item.zddm;
          })[0]
        ) {
          item.error = true;
        }
        if (this.examInfo.zpysyq != 4) {
          if (item.zdfl == "1" && index < 10) {
            this.list2.push(item);
          } else {
            this.list3.push(item);
          }
        } else {
          this.list3.push(item);
        }
      });
      // 学历错误字段
      this.kxgList.forEach((v) => {
        if (this.xlDict[v]) {
          $("." + v).addClass("canEdit");
          $(".xlbtn").removeClass("xlbtn");
        }
      });

      if (this.examInfo.zpysyq != 4) {
        let row = 0; // 0：左，1：右
        this.list3.forEach((v) => {
          if (v.zdbl == 100) {
            row = 0;
            v["row"] = "w-100";
            return;
          }
          if (row == 0) {
            v["row"] = "col-5";
            row = 1;
          } else {
            v["row"] = "col-7";
            row = 0;
          }
        });
      }
      this.$forceUpdate();
    },
    //获取文件限制
    getLimit() {
      // getUploadLimit({ module: "WorkCertificate" }).then((res) => {
      //   if (res.status) {
      //     this.astrict.gznxzm["ext"] =
      //       "." + res.data.extLimit.replaceAll(",", ",.");
      //     this.astrict.gznxzm["size"] = res.data.sizeLimit;
      //     let size = unitsChange(res.data.sizeLimit);
      //     this.astrict.gznxzm["text"] =
      //       "只支持" + res.data.extLimit + "，类型的文件，且不超过" + size;
      //   }
      // });
    },
    //上传文件
    uploadFile(file, key) {
      let _this = this;
      let fileRule = "";
      file = file.raw;
      let result = checkFile(file, this.astrict[key]);

      if (result.status) {
        if (key == "gznxzm") {
          fileRule = "WorkCertificate";
        }
        uploadFiles(fileRule, file, _this.examInfo.ksbmbh).then((res) => {
          if (res.status) {
            let resFile = res.data[0];
            _this.$message({
              type: "success",
              message: "上传成功",
            });
            _this.form[key] = resFile.fileId;
            this.$emit("input", this.form);
          }
        });
      } else {
        _this.$message({
          type: "error",
          message: result.message,
        });
      }
      this.$refs[key].clearFiles();
      return false;
    },

    getFieds() {
      getTemplateListByKsbmh(this.ksbmbh).then((res) => {
        if (res.status) {
          this.fields.push(...res.data);
          let row = 0;
          if (this.isPhoto) {
            this.fields.forEach((v) => {
              if (v.zdbl == 100) {
                row = 0;
                v["row"] = "col-sm-12";
                return;
              }
              if (row == 0) {
                v["row"] = "col-sm-5";
                row = 1;
              } else {
                v["row"] = "col-sm-7";
                row = 0;
              }
            });
          }
        }
      });
    },

    //获取考试表单列表
    getFormList() {
      this.list1 = [];
      if (this.examInfo.kslxmc != "其他申请报名") {
        this.list1.unshift(...this.addList);
      }
      let ksbmbh = this.ksbmbh;
      //获取考试报名表单
      getTemplateListByKsbmh(ksbmbh).then((res) => {
        if (res.status) {
          if(this.isShowYJ){
            res.data = res.data.filter(x=>{return x.txlx != 'shyj' && x.txlx != 'bmsm'})
          }
          this.list1.push(...res.data);
          // 科目选择方式1-选科方式 2-职位方式
          if (this.examInfo.kmxzfs == "1") {

            // 是否启用告知承诺0 否 1是
            if (this.examInfo.sfgzcn) {
              this.list1.unshift(...this.addxk);
            }
            // this.getXKList().then((res) => {
            //   this.list1[0]["data"].push(...res);
            //   this.listDiff();
            // });
            this.listDiff();
          } else {
            if (this.examInfo.sfgzcn) {
              this.addxk[0].zdfl = "2";
              this.list1.push(...this.addxk);
            }

            if (this.examInfo.kslxmc != "其他申请报名") {
              this.list1.unshift(...this.adddw);
              this.getZwList().then(() => {
                this.listDiff();
              });
            } else {

              this.listDiff();
            }
          }
        }
      });
      if (!this.form.sfty && !this.form.sfzstj) {
        this.getLimit();
      }
    },
    errorItem(e) {
      if (
        this.gdzd.filter((v) => {
          return v == e.zddm;
        })[0]
      ) {
        if (
          e.zddm == "bkgwxkm" &&
          this.form.kmxzfs == 2 &&
          this.zwList.length
        ) {
          let data = this.zwList.filter((v) => {
            return v.value == this.form[e.zddm];
          })[0];
          this.$emit("Itemclick", data);
        }
        return;
      }
      this.$emit("errorItem", e);
    },
    showPhone() {
      this.$confirm(
        "您的查看操作已被系统日志记录。电话信息为考生的敏感信息，审核人员对其有保密义务，请谨慎保管！",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      ).then(() => {
        realPhone(this.bmh).then((res) => {
          if (res.status) {
            this.form.yddh = res.data;
          }
        });
      });
    },
  },
  created() {
    let userInfo = JSON.parse(getStore("userInfo"));
    if (userInfo.roleNum == 2 || userInfo.roleNum == 3) {
      getNowExamHandle().then((res) => {
        if (res.status && res.data) {
          let shlb = "," + (res.data.shlb ? res.data.shlb : "") + ",";
          setStore("orgshlb", shlb);
          if (!shlb.includes(",9,")) {
            this.canShowPhone = false;
          }
        }
      });
    }
    this.getExamInfo();
  },
  watch: {
    print:{
      // deep: true,
      immediate:true,
      handler(val) {
        if(val && this.examInfo.kslxmc){
          this.getFormList();
        }
      },
    },
    form: {
      // deep: true,
      // immediate:true,
      handler(form) {
        if (form && this.headFlag  && this.examInfo.kslxmc) {
          this.headFlag = false;
          this.getFormList();
        }
      },
    },
    "form.zpdz": {
      deep: true,
      handler(val) {
        this.getFileUrl(val, true).then((res) => {
          if (res.status) {
            this.img = res.data;
          }
        });
      },
    },
    kxgList: {
      handler() {
        if(this.examInfo.kslxmc) {
          this.listDiff();
        }
      },
    },
    errorList: {
      handler() {
        // this.listDiff();
        this.list2.forEach((v) => {
          if (
            this.errorList.filter((x) => {
              return x == v.zddm;
            })[0]
          ) {
            v.error = true;
          } else {
            v.error = false;
          }
        });
        this.list3.forEach((v) => {
          if (
            this.errorList.filter((x) => {
              return x == v.zddm;
            })[0]
          ) {
            v.error = true;
          } else {
            v.error = false;
          }
        });
      },
    },
  },
};
</script>

<template>
  <div>
    <div>
      <div class="outerCotent person-form">
        <div class="msgContent fs-xs enrollForm">
          <el-row
            class="d-flex"
            style="max-height: 314px; line-height: 314px"
            v-if="examInfo.zpysyq != 4"
          >
            <el-col style="flex: 5" ref="list1Container">
              <draggable
                class="msgContent flexList fs-xs"
                :list="list2"
                group="people"
                :disabled="true"
              >
                <div
                  class="d-flex msgLabel canmove"
                  :class="[
                    'w' + element.zdbl,
                    (element.txlx == 'textarea' || element.txlx == 'shyj' || element.txlx == 'bmsm') ? 'msgtextarea' : '',
                  ]"
                  v-for="element in list2"
                  :key="element.sid"
                  @click="errorItem(element)"
                >
                  <div class="msgName">
                    <span style="color: red" v-if="element.sfbt">*</span
                    >{{ element.zdmc }}
                  </div>
                  <div
                    class="msgVal selectShow flexList"
                    :class="
                      (element.canEdit ? 'canEdit ' : '') +
                      (element.error ? 'errorzd ' : '') +
                      element.zddm
                    "
                  >
                    <yzFormItem
                      :innerElement="element"
                      v-model="form"
                      :examInfo="examInfo"
                      @change="getForm"
                      :readOnly="
                        element.canEdit
                          ? false
                          : element.readOnly
                          ? element.readOnly
                          : readOnly
                      "
                    ></yzFormItem>
                    <a
                      v-if="element.zddm == 'yddh' && canShowPhone && showType != 'print'"
                      href="javascript:;"
                      style="width: 7%"
                      @click.stop="showPhone"
                      >查看电话</a
                    >
                  </div>
                </div>
              </draggable>
            </el-col>
            <el-col class="guding-img" style="flex: 1">
              <img
                :src="
                  form.zpdz
                    ? img
                    : require('@/assets/images/person/person-none.png')
                "
                alt=""
                style="width: 120px; height: 160px; margin-top: 10px"
              />
            </el-col>
          </el-row>
          <el-row>
            <draggable
              class="msgContent flexList fs-xs"
              :list="list3"
              group="people"
              :disabled="true"
            >
              <div
                class="d-flex msgLabel canmove"
                :class="[
                  'w' + element.zdbl,
                  (element.txlx == 'textarea' || element.txlx == 'shyj' || element.txlx == 'bmsm') ? 'msgtextarea' : '',
                  element.row,
                ]"
                v-for="element in list3"
                :key="element.sid"
                @click="errorItem(element)"
              >
                <div class="msgName">
                  <span style="color: red" v-if="element.sfbt">*</span
                  >{{ element.zdmc }}
                </div>
                <div
                  class="msgVal selectShow flexList"
                  :class="
                    (element.canEdit ? 'canEdit ' : '') +
                    (element.error ? 'errorzd ' : '') +
                    element.zddm
                  "
                >
                  <yzFormItem
                    :innerElement="element"
                    v-model="form"
                    :examInfo="examInfo"
                    @change="getForm"
                    :readOnly="
                      element.canEdit
                        ? false
                        : element.readOnly
                        ? element.readOnly
                        : readOnly
                    "
                  ></yzFormItem>
                  <a
                    v-if="element.zddm == 'yddh' && canShowPhone && showType != 'print'"
                    href="javascript:;"
                    @click.stop="showPhone"
                    style="width: 7%"
                  >查看电话</a
                  >
                </div>
              </div>
            </draggable>
          </el-row>
          <div
            class="flexList msgLabel w100"
            style="height: 60px"
            v-if="!readOnly || kxgList.length != 0"
          >
            <div class="msgName"></div>
            <div class="msgVal flexList">
              <button
                type="button"
                class="btn btn-info h35 mr-2 sub-btn"
                @click="save"
              >
                保存信息
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog title="提示" :visible.sync="dialogVisible" width="30%">
      <span>信息提交后无法修改, 是否继续?</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="save">保存并返回</el-button>
        <el-button type="primary" @click="submitFrom">确认提交</el-button>
      </span>
    </el-dialog>
  </div>
</template>
