<template>
  <a href="javascript:;">
    <div class="text-success" v-if="shzt == 1">
      <i class="iconfont icon-a-ze-checkedCopy2 mr-2"></i
      >通过
    </div>
    <div class="text-danger" v-else-if="shzt == 2">
      <i class="iconfont icon-ze-clear text-danger mr-2"></i
      >未通过
    </div>
    <div class="text-info" v-else>
      <i class="iconfont icon-icon_minus-circled mr-2"></i
      >待审核
    </div>
  </a>
</template>
<script>
export default {
  props:{
    shzt: {
      default: 0
    }
  }
}
</script>