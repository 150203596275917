<script >
import QualificationAuditStatisticsList from "@/views/pages/admin/statistics/QualificationAuditStatisticsList.vue";
import {qualificationAuditStatistics} from "@/api/admin/user/institutionManage";

export default {
  components: {
    QualificationAuditStatisticsList
  },
  data() {
    return {
      showmodal: false,
      list: []
    };
  },
  methods: {
    show(){
      qualificationAuditStatistics().then(res=>{
        if (res.status) {
          this.data = res.data
          this.showmodal = true
        }
      })

    }
  },
  mounted() {
  }
};
</script>

<template>
  <b-modal id="addInstiUser" v-model="showmodal" centered title="资格审核统计" size="lg"
           title-class="font-18" hide-footer>
    <QualificationAuditStatisticsList ref="list" :data="data"></QualificationAuditStatisticsList>
  </b-modal>
</template>

<style scoped>

</style>