// 考试报名信息
import {
  getRequest,
  postRequest,
  putRequest,
  deleteRequest
} from "@/libs/axios";

export const getTemplateList = function() {
  return getRequest("/yethan/register/sysfield/listAll")
}

export const updateTemplate = function(params) {
  return putRequest("/yethan/register/sysfield", params)
}

export const addTemplateList = function(params) {
  return postRequest("/yethan/register/sysfield", params)
}


export const deleteTemplate = function(sids) {
  return deleteRequest(`/yethan/register/sysfield/${sids}`)
}


export const saveTemplateList = function(name, params) {
  return postRequest(`/yethan/register/field/saveList/${name}`, params)
}

// 获取某次考试的组件列表


export const getTemplateListByKsbmh = function(ksbmbh) {
  return getRequest(`/yethan/register/field/list`, {
    ksbmbh: ksbmbh
  }, false)
}

// 模板下拉
export const getTemplateName = function(templateName) {
  return getRequest(`/yethan/register/template/easyList`, {
    templateName: templateName
  })
}
// 将字段保存为模板
export const saveTemplate = function(templateName, molde) {
  return postRequest(`/yethan/register/template/save/${templateName}`, molde)
}
// 模板导入
export const importTemplate = function(mid) {
  return getRequest(`/yethan/register/template/fieldList/${mid}`)
}
// 模板删除
export const deleteModule = function(mid) {
  return deleteRequest(`/yethan/register/template/${mid}`)
}
