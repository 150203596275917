<template>
  <div class="msgContent flexList fs-xs">
    <div class="flexList" style="flex-wrap: wrap; padding-left: 0">
      <div
          class="flexList w-50"
          :class="'w-' + v.width"
          v-for="(v, i) in bounds"
          :key="i"
          style="height: 30px; line-height: 30px"
      >
        <div class="msgName">{{ v.label }}</div>
        <div class="msgVal flexList">
          <div class="line1 use-circle" v-if="v.type == Boolean">
            {{ form[v.bound] ? "是" : "否" }}
          </div>
          <div class="line1 use-circle" v-if="v.type == 'shzt'">
            <handleStatus :shzt="form[v.bound]"></handleStatus>
          </div>
          <div class="line1 use-circle" v-else-if="v.type == 'money'">
            {{ form[v.bound] / 100 }}元
          </div>
          <div class="line1 use-circle" v-else-if="v.type == 'datetime'">
            {{formatDateDetails(form[v.bound])}}
          </div>
          <div class="line1 use-circle" v-else-if="v.type == 'join'">
            {{ [form[v.bound[0]], form[v.bound[1]]].join(v.join) }}
          </div>
          <div class="line1 use-circle" v-else-if="v.type == 'link'">
            <a v-if="form[v.bound]" @click="$yzImgDialog().show(getFileUrl(form[v.bound]))" href="javascript:;">{{ v.linkDes?v.linkDes:v.label }} 查看</a>
            <span v-else>暂未上传</span>
          </div>
          <div class="line1 use-circle" v-else>{{ form[v.bound] }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import handleStatus from '@/components/table/handleStatus.vue'
export default {
  props: {
    bounds: Array,
    form: Object,
  },
  components:{
    handleStatus
  }
};
</script>

<style>
@media screen and (min-width: 1200px){
  .msgName {
      min-width: 145px;
      width: 145px;
  }
}

</style>
