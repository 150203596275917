// 选科
import { getRequest,postRequest,putRequest,patchRequest,deleteRequest,downloadRequest} from "@/libs/axios";

// 考试选科分类
export const getListPage = params => {
  return getRequest("/yethan/register/classification/listPage", params);
};
// 添加选科
export const addSubject = params => {
  return postRequest("/yethan/register/classification", params);
};
// 修改选科
export const editSubject = params => {
  return putRequest("/yethan/register/classification", params);
};
// 删除选科
export function deleteSubject(sid) {
    return deleteRequest('/yethan/register/classification/'+sid);
}

// 选科详情
export const getDetail= params =>{
  return getRequest("/yethan/register/classification/"+params);
}
// 更新启用状态
export const updateSubjectQyzt = params => {
  return patchRequest("/yethan/register/classification/updateClassificationQyzt", params);
};
// 免试状态
export const updateClassificationSfms = params => {
  return patchRequest("/yethan/register/classification/updateClassificationSfms", params);
};


// 添加选科和科目关联
export const addClassSubject = params => {
  return postRequest("/yethan/register/classificationSubject", params);
}
// 删除选科和科目关联
export function deleteClassSubject(sid) {
    return deleteRequest('/yethan/register/classificationSubject/'+sid);
}

// 编辑选科和科目关联
export const editClassSubject = params => {
  return putRequest("/yethan/register/classificationSubject", params);
}

// 筛选
export function classificationEasyList(data={}) {
    return getRequest('/yethan/register/classification/easyList',data);
}
// 带科目的筛选
export function classificationInfoList(data={}) {
    return getRequest('/yethan/register/classification/infoList',data);
}

//选科导出excel
export function exporClassificationExcel(data={}) {
    return downloadRequest('/yethan/register/classification/exportExcel',data);
}
