<template>
  <div>
    <div class="blue-font mt-2 mb-2">
      <i class="iconfont icon-ze-user-circle-o mr-2"></i>减免信息
    </div>
    <yzShowTable
      :form="form"
      :bounds="bounds"
      style="margin-bottom: 10px"
    ></yzShowTable>
    <div v-if="showCheck">
      <div class="d-flex mt-2 mb-3">
        <div class="form-check mr-2">
          <input
            v-model="commitForm.shzt"
            :disabled="!canEdit"
            type="radio"
            name="shzt"
            id="zgshzt1"
            value="1"
            class="form-check-input"
          />
          <label for="zgshzt1" class="form-check-label">通过</label>
        </div>
        <div class="form-check mr-2">
          <input
            v-model="commitForm.shzt"
            :disabled="!canEdit"
            type="radio"
            name="shzt"
            id="zgshzt2"
            value="2"
            class="form-check-input"
          />
          <label for="zgshzt2" class="form-check-label">不通过</label>
        </div>
        <div class="form-check mr-2">
          <input
            v-model="commitForm.shzt"
            :disabled="!canEdit"
            type="radio"
            name="shzt"
            id="zgshzt4"
            value="0"
            class="form-check-input"
          />
          <label for="zgshzt4" class="form-check-label"
            >重置(恢复到初始状态)</label
          >
        </div>
      </div>
      <div class="d-flex check-distri mb-3">
        <label>审核金额：</label>
        <div class="col-sm-10 p-0">
          <input
            v-model="commitForm.sqjmje"
            :readonly="!canEdit"
            type="number"
            oninput="if(value.length>5) value=value.slice(0,5)"
            placeholder="审核金额"
            class="form-control w-100 h30"
          />
        </div>
      </div>
      <div class="d-flex check-distri mb-3">
        <label>审核说明：</label>
        <div class="col-sm-10 p-0">
          <textarea
            v-model="commitForm.shsm"
            type="text"
            oninput="if(value.length>200) value=value.slice(0,200)"
            placeholder="审核说明"
            class="form-control w-100"
          />
        </div>
      </div>
      <div class="mt-3">
        <button
          type="button"
          class="btn btn-info h30 mr-2 sub-btn"
          v-if="canEdit"
          @click="handle()"
        >
          提交减免审核结果
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import yzShowTable from "@/components/form/yzShowTable";
import {
  getRegisterFeeApplyByBmh,
  handleRegisterFeeApply,
} from "@/api/admin/apply/apply";
import { fileSizeCover } from "@/utils/file";
export default {
  components: {
    yzShowTable,
  },
  props: {
    bmh: String,
    showCheck: {
      default: true,
    },
  },
  data() {
    return {
      form: {},
      commitForm: {},
      bounds: [],
      canEdit: true,
    };
  },
  methods: {
    init() {
      getRegisterFeeApplyByBmh(this.bmh).then((res) => {
        if (res.status && res.data) {
          this.form = res.data;
          this.form.jmje=this.form.yjfje
          this.commitForm.shzt = this.form.shzt;
          this.commitForm.shsm = this.form.shsm;
          this.commitForm.jmje = this.form.jmje / 100;
          this.commitForm.sqjmje=this.form.yjfje/100
          if (this.form.shzt == 1) {
            this.canEdit = false;
          }
          this.bounds = [
            {
              label: "考试年份",
              bound: "ksnf",
              width: "50",
            },
            {
              label: "考试名称",
              bound: "ksmc",
              width: "50",
            },
            {
              label: "考生姓名",
              bound: "xm",
              width: "50",
            },
            {
              label: "报名费用",
              bound: "yjfje",
              type: "money",
              width: "50",
            },
            {
              label: "申请减免",
              bound: "sqjmje",
              type: "money",
              width: "100",
            },
            {
              label: "申请原因",
              bound: "jmyy",
              width: "100",
            },
            {
              label: "原因描述",
              bound: "jmxs",
              width: "100",
            },
            {
              label: "支撑材料",
              bound: "jmfj",
              type: "link",
              linkDes: this.form.jmfjmc
                ? this.form.jmfjmc + " / " + fileSizeCover(this.form.jmfjdx)
                : "",
              width: "100",
            },
            {
              label: "申请时间",
              bound: "createTime",
              type: "datetime",
              width: "100",
            },
            {
              label: "申请状态",
              bound: "shzt",
              type: "shzt",
              width: "100",
            },
            {
              label: "审核金额",
              bound: "jmje",
              type: "money",
              width: "100",
            },
            {
              label: "审核时间",
              bound: "shsj",
              type: "datetime",
              width: "100",
            },
          ];
          this.showUser = true;
        }
      });
    },
    handle() {
      let form = JSON.parse(JSON.stringify(this.commitForm));
      form.jmje = form.jmje * 100;
      handleRegisterFeeApply(this.form.sid, form).then((res) => {
        if (res.status) {
          this.$message({
            type: "success",
            message: "审核成功!",
          });
          this.$emit("success");
        }
      });
    },
  },
  mounted() {
    this.init();
  },
};
</script>

<style>
.guding-img {
  width: 20%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 1px solid #dfdfdf;
  border-right: 1px solid #dfdfdf;
}

.no-border-top {
  border-top: unset !important;
}
.no-border-bottom {
  border-bottom: unset !important;
}
</style>
