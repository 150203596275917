<style scoped>
.pay-money {
  height: unset;
}
.msgName {
  width: 155px;
  min-width: 155px;
}

.circle {
  position: relative;
  z-index: 50;
  transition: all 0.3s;
  cursor: pointer;
}

.msgLabel {
  cursor: pointer;
  height: 35px;
  line-height: 35px;
  font-size: 12px;
  user-select: none;
}

.diff-width {
  flex-wrap: wrap;
}

.outerCotent {
  border-bottom: 1px solid #dfdfdf;
}

.use-circle {
  user-select: none;
}

.circle::after {
  content: " ";
  border: 1px solid red;
  border-radius: 50% / 50%;
  position: absolute;
  left: -5px;
  top: -7px;
  z-index: 48;
  width: 120%;
  height: 30px;
  min-width: 20px;
}

.personPic {
  height: 175px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.personPic img {
  width: 120px;
  height: 160px;
}

.msgLabel.msgtextarea {
  height: 100px !important;
}

.mb-3 {
  margin-bottom: 0.5rem !important;
}

.mr-2 {
  top: 3px;
}

/deep/.el-input__inner {
  height: 30px;
  line-height: 30px;
}

/deep/.el-input__icon {
  line-height: inherit;
}
.flag div {
  display: inline-block;
}
.msgLabel {
  width: 50%;
}
.table th {
  background-color: #f3f5fb;
}
</style>

<script type="text/javascript">
import { getUserBmk } from "@/api/examination_user.js";

import {
  getRegisterUserInfo,
  checkRegisterUser,
  registerUserSubject,
} from "@/api/admin/register/registerUser.js";
import { getSysConfig } from "@/api/common/public.js";
import ImageInfo from "@/components/user/register_user/img-info.vue";
import examForm from "@/components/form/examForm.vue";
import { getNowExamHandle,getExamDetails } from "@/api/admin/exam/examRecord";
import { getUserExamFree } from "@/api/examination_user";
import breaksHandle from "@/views/pages/admin/components/breaks-handle.vue";
import registerInfo from "@/components/examInfo/registerInfo";
import freeExam from "@/components/examInfo/freeExam";
import statusInfo from "@/components/examInfo/statusInfo";
import zwInfo from "@/components/examInfo/zwInfo.vue";
import {formatWithSeconds} from "@/libs/common";

export default {
  components: {
    ImageInfo,
    examForm,
    breaksHandle,
    registerInfo,
    freeExam,
    statusInfo,
    zwInfo,
  },
  props: {
    ksbmbh: {
      type: String,
      default: "",
    },
    bmh: {
      type: String,
      default: "",
    },
    isPhoto: {
      type: Boolean,
      default: false,
    },
    type: {
      default: 1,
    },
    checkType: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      sdsj: 0,
      RegisterLockTimeMax: 10,
      errorInfoList: [],
      errorInfoLabelList: [],
      errorInfoStr: "",
      errorInfoDmStr: "",
      feeInfo: "",
      subjectList: null,
      feeNum: "",
      info: {},
      isOrg: false,
      dictList: [],
      fields: [
        {
          sfbt: true,
          zddm: "xm",
          zdmc: "姓名",
          data: "",
          zdfl: "1",
          txlx: "input",
          zdbl: "50",
          readOnly: true,
        },
        {
          sfbt: true,
          glzdb: true,
          zddm: ["xbm", "xb"],
          zdmc: "性别",
          zdbmc: "dict_xb",
          zdcd: 2,
          zdfl: "1",
          txlx: "radio",
          zdbl: 50,
          readOnly: true,
        },
        {
          zddm: ["sfzjlxm", "sfzjlxmc"],
          zdbmc: "dict_sfzjlx",
          glzdb: true,
          zdcd: 30,
          zdfl: "1",
          sfbt: true,
          zdmc: "证件类型",
          txlx: "select",
          zdbl: 50,
          readOnly: true,
        },
        {
          sfbt: true,
          zddm: "sfzjh",
          zdmc: "证件号码",
          data: "",
          zdfl: "1",
          txlx: "input",
          zdbl: "50",
          readOnly: true,
        },

        {
          zddm: "csrq",
          zdbmc: "",
          glzdb: false,
          zdcd: 10,
          zdfl: "1",
          sfbt: true,
          zdmc: "出生年月",
          txlx: "date",
          zdbl: 50,
          readOnly: true,
        },
        {
          glzdb: false,
          sfbt: true,
          txlx: "input",
          zdbl: 50,
          zdcd: 50,
          zddm: "jg",
          zdfl: "1",
          zdmc: "籍贯",
          readOnly: true,
        },
      ],
      examHandleInfo: {
        shlb: "",
      },
      msInfo: null,
      msLabel: [],
      zslist: [
        "资格证书名称",
        "资格证书编号",
        "证书专业名称",
        "证书取得时间",
        "证明电子材料",
      ],
      xllist: ["毕业院校", "毕业时间", "证书编号", "所学专业", "证明电子材料"],
      info1: {
        zgshzt: "",
        zpshzt: "",
      },
      zwInfo: {},
      zwInfoShow: false,
      examInfo:{},
      showReduction:true,
    };
  },
  methods: {
    errorItem(item) {
      let index = this.errorInfoList.indexOf(item.zddm);
      if (index > -1) {
        //删除圈圈时触发
        this.errorInfoList.splice(index, 1);
        this.errorInfoLabelList.splice(index, 1);
      } else {
        //添加圈圈时触发
        this.errorInfoList.push(item.zddm);
        this.errorInfoLabelList.push(item.zdmc);
      }

      this.info.ischeck = this.errorInfoList.length ? "0" : "1";
      // 重新获取错误字段
      this.errorInfoStr = "";
      this.errorInfoDmStr = "";
      this.errorInfoLabelList.forEach((k) => {
        if (k) {
          this.errorInfoStr += "、" + k.replaceAll(" ", "");
        }
      });
      this.errorInfoList.forEach((k) => {
        if (k) {
          this.errorInfoDmStr += "、" + k.replaceAll(" ", "");
        }
      });
      if (this.errorInfoStr.length > 0) {
        this.errorInfoStr = this.errorInfoStr.substring(
          1,
          this.errorInfoStr.length
        );
        this.errorInfoDmStr = this.errorInfoDmStr.substring(
          1,
          this.errorInfoDmStr.length
        );
        this.info.zgshsm = this.errorInfoStr + "不符合";
        this.info1.zgshzt = 2;
      } else {
        if(this.info.zgshzt == 0) {
          this.info.zgshsm = "";
          this.info1.zgshzt = "1";
        }
      }
    },
    Itemclick(data) {
      this.zwInfo = data;
      this.zwInfoShow = true;
    },

    // // 查询考生考试科目
    // getRegisterUserSubject() {
    //   let params = {
    //     ksbmbh: this.ksbmbh,
    //     sfzjh: this.info.sfzjh,
    //     bmh: this.info.bmh,
    //   };
    //   // 查询考生收费情况
    //   registerUserSubject(params).then((res) => {
    //     if (res.status) {
    //       this.subjectList = res.data;
    //     }
    //   });
    //   params = {
    //     ksbmbh: this.ksbmbh,
    //     sfzjh: this.info.sfzjh,
    //   };
    //   // 查询免试原因
    //   if (this.info.mssqzt != -1) {
    //     getUserExamFree(params).then((res) => {
    //       if (res.status && res.data) {
    //         this.msInfo = res.data[0];
    //         if (this.msInfo.txlx == 1) {
    //           this.msLabel = this.zslist;
    //         } else {
    //           this.msLabel = this.xllist;
    //         }
    //       }
    //     });
    //   }
    // },
    // 审核考生数据
    checkRegister(checkType) {
      let param = {
        bmh: this.bmh,
        checkType: checkType,
      };
      if (checkType == "photo") {
        param["zpshzt"] = this.info.zpshzt;
        param["zpshsm"] = this.info.zpshsm;
      }
      if (checkType == "education") {
        param["xlshzt"] = this.info.xlshzt;
        param["xlshsm"] = this.info.xlshsm;
      }
      if (checkType == "lock") {
        param["zgsdzt"] = this.info1.zgsdzt;
        param["zgsdsm"] = this.info1.zgsdsm ? this.info1.zgsdsm : "";
      }
      if (checkType == "quality") {
        param["cwzd"] = this.errorInfoStr;
        param["cwzddm"] = this.errorInfoDmStr;
        param["zgshzt"] = this.info.zgshzt;
        param["zgshsm"] = this.info.zgshsm ? this.info.zgshsm : "";
        param["zgshbz"] = this.info.zgshbz;
        param["yjfje"] = Number(this.feeNum * 100);
        param["sfyxxg"] = this.info.sfyxxg != null;
        // 资格审核锁定
        if (this.info.zgshzt == 9) {
          param["sdjcsj"] = this.info.sdjcsj;
        } else {
          param["sdjcsj"] = "";
        }
        // 允许考生修改
        if (this.info.sfyxxg != null && this.info.sfyxxg) {
          param["sfyxxg"] = this.info.sfyxxg;
          param["yxxgsj"] = this.info.yxxgsj;
        } else {
          param["sfyxxg"] = this.info.sfyxxg;
          param["yxxgsj"] = "";
        }
      }
      if (checkType == "final") {
        param["shzt"] = this.info.shzt;
        param["shsm"] = this.info.shsm;
      }
      if (this.isOrg) {
        if (checkType == "quality") {
          param["zgshzt"] = this.info1.zgshzt;
          param["zgsdzt"] = this.info1.zgsdzt;
        }
        if (checkType == "photo") {
          param["zpshzt"] = this.info1.zpshzt;
        }
      }
      if (
        checkType == "quality" &&
        param["zgshzt"] == 2 &&
        param["zgshsm"].length <= 0
      ) {
        this.$notify.warning("资格审核不同意，必须填写说明。");
        return;
      }
      checkRegisterUser(param).then((res) => {
        if (res.status) {
          this.$message({
            type: "success",
            message: "审核成功!",
          });
          this.$emit("success");
          this.registerUserInfo();
        }
      });
    },
    radiochange() {
      return
      let val = this.info1.zgshzt
      if (val == "1") {
        this.errorInfoList = [];
        this.errorInfoLabelList = [];
        this.errorItem({});
      }
    },
    registerUserInfo() {
      getRegisterUserInfo(this.bmh).then((res) => {
        if (res.status) {
          let data = res.data;
          getUserBmk({
            ksbmbh: this.ksbmbh,
            sfzjh: data.sfzjh,
          }).then((res) => {
            if (res.status) {
              let list = res.data[res.data.length - 1] || {};
              for (const key in list) {
                if (!data[key]) {
                  data[key] = list[key];
                }
              }
              this.info = data;
              this.info1 = JSON.parse(JSON.stringify(data));
              this.errorInfoStr = this.info.cwzd || "";
              this.errorInfoDmStr = this.info.cwzddm || "";
              this.errorInfoLabelList = this.errorInfoStr.split(/[、，,;]/);
              this.errorInfoList = this.errorInfoDmStr.split(/[、，,;]/);
              // this.getRegisterUserSubject();
              this.$parent.ksmc = this.info.ksmc;
            }
          });
        }
      });
    },
    // 设置锁定时间
    setTime() {
      let curTime = new Date();
      let val = curTime.setHours(curTime.getHours() + this.sdsj);
      val = new Date(val);
      let yy = val.getFullYear();
      let mm = val.getMonth() + 1;
      let dd = val.getDate();
      let hh = val.getHours();
      let mf =
        val.getMinutes() < 10 ? "0" + val.getMinutes() : val.getMinutes();
      let ss =
        val.getSeconds() < 10 ? "0" + val.getSeconds() : val.getSeconds();
      this.info.sdjcsj =
        yy + "-" + mm + "-" + dd + " " + hh + ":" + mf + ":" + ss;
      this.info.yxxgsj =
        yy + "-" + mm + "-" + dd + " " + hh + ":" + mf + ":" + ss;
    },
    //获取考试详情用来判断减免显示
    getExamInfo() {
     let dateTime=formatWithSeconds(new Date())
      getExamDetails(this.ksbmbh).then((res) => {
        if (res.status) {
          this.examInfo = res.data;
          if(dateTime>=this.examInfo.jmshkssj&&dateTime<=this.examInfo.jmshjssj){

            this.showReduction=true
          }else{
            this.showReduction=false
          }
        }
      });
    },
  },
  mounted() {
    this.getExamInfo()
    this.registerUserInfo();
    let userInfo = JSON.parse(this.getStore("userInfo"));
    // 是否是机构审核人员
    if (
      userInfo.userType.substring(1, 2) == "1" ||
      userInfo.userType.substring(2, 3) == "1"
    ) {
      this.isOrg = true;
    } else if (
      userInfo.userType.substring(8, 9) == "1" ||
      userInfo.userType.substring(9, 10) == "1"
    ) {
      this.isOrg = false;
    } else {
      window.location.href = "/home";
    }
    if (this.isOrg) {
      getNowExamHandle().then((res) => {
        if (res.status) {
          this.examHandleInfo = res.data;
        }
      });
    }
    getSysConfig("RegisterLockTimeMax").then((res) => {
      if (res.status) {
        this.RegisterLockTimeMax = Number(res.data);
      }
    });
  },
  computed: {
    hasLockPower() {
      return this.examHandleInfo.shlb.indexOf("6") != -1;
    },
    hasUnLockPower() {
      return this.examHandleInfo.shlb.indexOf("12") != -1;
    },
    hasUpdatePower() {
      return this.examHandleInfo.shlb.indexOf("5") != -1;
    },
    hasCheckBtn() {
      return this.hasLockPower || this.hasUnLockPower || (this.info.sfkysh && !(this.info.zgsdzt == 1 && this.hasUpdatePower) && !(this.info.zgshzt != 0 && this.hasUpdatePower))
    },
  },
  watch: {
    'info.zgshzt':{
      deep:true,
      handler(val){
        if(val == '1'){
          this.errorInfoList = []
          this.errorInfoLabelList = []
          this.errorItem({})
        }
      }
    }
  },
};
</script>
<template>
  <div id="person" style="background: #fff">
    <div
      class="blue-font mt-2 mb-2 d-flex align-item-center justify-content-between"
    >
      <div><i class="iconfont icon-ze-user-circle-o mr-2"></i>报名信息（报名来源：{{info.bmly?info.bmly:'网站报名'}}）</div>
      <span class="ml-3">报名时间：{{ info.createTime }}</span>
    </div>
    <div class="mt-2 show card" style="max-height: 450px; overflow-y: auto">
      <div class="outerCotent form-sure" style="border-bottom: 0">
        <input type="hidden" class="msgValInput" value="" name="sid" />
        <div class="msgContent flexList fs-xs">
          <examForm
            v-model="info"
            :bmh="info.bmh"
            :ksbmbh="this.ksbmbh"
            :readOnly="true"
            :kxgList="[]"
            :errorList="errorInfoList"
            @errorItem="errorItem"
            @Itemclick="Itemclick"
          ></examForm>
        </div>

        <div class="w-100">
          <ImageInfo
            :sfzjh="info.sfzjh"
            v-if="checkType == 'photo' && info.sfzjh"
          ></ImageInfo>
        </div>
      </div>
    </div>
    <div class="card" v-if="info.kmxzfs == 1 || info.kslxmc != '其他申请报名'">
      <div class="blue-font mt-2 mb-2">
        <i class="iconfont icon-ze-user-circle-o mr-2"></i>报考信息
      </div>
      <div class="mt-3">
        <div class="outerCotent person-form">
          <registerInfo v-model="info"></registerInfo>
        </div>
      </div>
    </div>
    <div class="card" v-if="info.mssqzt != null && info.mssqzt != '-1'">
      <div class="blue-font mt-2 mb-2">
        <i class="iconfont icon-ze-user-circle-o mr-2"></i>免试信息
      </div>
      <div class="mt-3 outerCotent person-form">
        <freeExam v-model="info"></freeExam>
      </div>
    </div>
    <!-- <div class="card">
      <div class="">
        <div class="blue-font mt-2 mb-2">
          <i class="iconfont icon-ze-user-circle-o mr-2"></i>报名状态
        </div>
        <div class="mt-3">
          <statusInfo v-model="info"></statusInfo>
        </div>
      </div>
    </div> -->

    <div v-if="isOrg">

      <div v-if="checkType == 'quality'">
        <div v-if="info.sfshsj && (hasLockPower || hasUnLockPower)">
          <div class="d-flex mt-2 mb-3">
            <div class="form-check mr-2" v-if="hasLockPower">
              <input
                  v-model="info1.zgsdzt"
                  type="radio"
                  name="zgsdzt"
                  id="zgshzt3"
                  value="1"
                  class="form-check-input"
              />
              <label for="zgshzt3" class="form-check-label">锁定</label>
            </div>
            <div class="form-check mr-2" v-if="hasUnLockPower">
              <input
                  v-model="info1.zgsdzt"
                  type="radio"
                  name="zgsdzt"
                  id="zgshzt3"
                  value="0"
                  class="form-check-input"
              />
              <label for="zgshzt3" class="form-check-label">解锁</label>
            </div>
          </div>
          <textarea
              v-if="hasLockPower"
              v-model="info1.zgsdsm"
              rows="3"
              class="form-control w-100 mt-2 line3"
              placeholder="锁定说明"
              maxlength="255"
          ></textarea>
          <div class="mt-2">
            <button
                type="button"
                class="btn btn-info h30 mr-2 sub-btn"
                @click="checkRegister('lock')"
            >
              提交锁定设置
            </button>
          </div>
        </div>
        <!--  可以审核 且  未锁定 且 （有修改权限  或 第一次审核）  -->
        <div v-if="info.sfkysh && info.zgsdzt != 1 && (!hasUpdatePower || (hasUpdatePower && info.zgshzt==0))">
          <div class="blue-font mt-2">资格审核</div>
          <div class="d-flex mt-2 mb-3">
            <div class="form-check mr-2" >
              <input
                v-model="info1.zgshzt"
                type="radio"
                name="zgshzt"
                id="zgshzt1"
                value="1"
                class="form-check-input"
              />
              <label for="zgshzt1" class="form-check-label">通过</label>
            </div>
            <div class="form-check mr-2">
              <input
                v-model="info1.zgshzt"
                type="radio"
                name="zgshzt"
                id="zgshzt2"
                value="2"
                class="form-check-input"
              />
              <label for="zgshzt2" class="form-check-label">不通过</label>
            </div>
            <div class="form-check mr-2">
              <input
                v-model="info1.zgshzt"
                type="radio"
                name="zgshzt"
                id="zgshzt4"
                value="0"
                class="form-check-input"
              />
              <label for="zgshzt4" class="form-check-label"
                >重置(恢复到初始状态)</label
              >
            </div>
          </div>
          <textarea
            v-if="!(info.zgsdzt == 1 && hasUpdatePower && info.zgshzt != 0)"
            v-model="info.zgshsm"
            rows="3"
            class="form-control w-100 mt-2 line3"
            placeholder="资格审核说明"
            maxlength="255"
          ></textarea>
          <div class="mt-2">
            <button
                type="button"
                class="btn btn-info h30 mr-2 sub-btn"
                @click="checkRegister('quality')"
            >
              提交资格审核结果
            </button>
          </div>
        </div>
        <div v-else>
          <div class="blue-font mt-2">资格审核</div>
          <div class="d-flex mt-2 mb-3">
            审核状态：{{
              info.zgshzt == 1
                ? "通过"
                : info.zgshzt == 9
                ? "锁定"
                : info.zgshzt == 2
                ? "未通过"
                : "待审"
            }}
          </div>
          <div class="d-flex mt-2 mb-3">审核说明：{{ info.zgshsm }}</div>
        </div>
      </div>
    </div>
    <div v-else>
      <div v-if="info.sfkysh && (checkType == '' || checkType == 'quality')">
        <div class="blue-font mt-2 mb-2">
          <i class="iconfont icon-ze-user-circle-o mr-2"></i>资格审核
        </div>
        <div class="d-flex mt-2 mb-3">
          <div class="form-check mr-2 mt-1">
            <input
              v-model="info.zgshzt"
              type="radio"
              name="zgshzt"
              id="zgshzt1"
              value="1"
              class="form-check-input"
            />
            <label for="zgshzt1" class="form-check-label">通过</label>
          </div>
          <div class="form-check mr-2 mt-1">
            <input
              v-model="info.zgshzt"
              type="radio"
              name="zgshzt"
              id="zgshzt2"
              value="2"
              class="form-check-input"
            />
            <label for="zgshzt2" class="form-check-label">不通过</label>
          </div>
          <div class="form-check mr-2 mt-1">
            <input
              v-model="info.zgshzt"
              type="radio"
              name="zgshzt"
              id="zgshzt4"
              value="0"
              class="form-check-input"
            />
            <label for="zgshzt4" class="form-check-label"
              >重置(恢复到初始状态)</label
            >
          </div>
          <div class="form-check mr-2 mt-1">
            <input
              v-model="info.zgshzt"
              type="radio"
              name="zgshzt"
              id="zgshzt3"
              value="9"
              class="form-check-input"
            />
            <label for="zgshzt3" class="form-check-label">锁定</label>
          </div>
          <div class="mr-2 mt-1 flexList">
            <el-select
              v-if="info.zgshzt == 9"
              class="mx-1 mr-1 blueBorder"
              style="width: 120px"
              placeholder="锁定时长"
              v-model="sdsj"
              size="small"
              @change="setTime"
            >
              <el-option :value="0" label="锁定时长"></el-option>
              <el-option
                v-for="i in RegisterLockTimeMax"
                :key="i"
                :value="i"
                :label="i + '小时'"
              ></el-option>
            </el-select>

            <div class="flexList" style="margin-left: 10px">
              <input
                type="checkbox"
                v-model="info.sfyxxg"
                class="mr-1"
                value="true"
                id="sfyxxg"
              />
              <label
                for="sfyxxg"
                class="form-check-label"
                style="line-height: 19px"
                >允许考试修改信息</label
              >，在
            </div>
            <el-date-picker
              class="ml-1 mr-1 el-input"
              v-model="info.yxxgsj"
              type="datetime"
              placeholder="设置截止时间时间"
            ></el-date-picker>
            之前
          </div>
        </div>
        资格审核说明:
        <textarea
          v-model="info.zgshsm"
          rows="3"
          class="form-control w-100 mt-2 line3"
          placeholder="资格审核说明"
          maxlength="255"
        ></textarea>
        审核备注:
        <textarea
            v-model="info.zgshbz"
            rows="3"
            class="form-control w-100 mt-2 line3"
            placeholder="审核备注"
            maxlength="255"
        ></textarea>
        <div class="mt-2">
          <button
            type="button"
            class="btn btn-info h30 mr-2 sub-btn"
            @click="checkRegister('quality')"
          >
            提交资格审核结果
          </button>
        </div>
      </div>
    </div>
    <breaksHandle
      v-if="(checkType == 'breaks' || !isOrg) && info.jmzt && info.jmzt != -1"
      :bmh="bmh"
      :showCheck="showReduction"
      @success="$emit('success')"
    ></breaksHandle>
    <b-modal
      id=""
      v-model="zwInfoShow"
      centered
      size="lg"
      scrollable
      :title="'职位详情（职位信息仅供参考，请以公告为准！）'"
      title-class="font-18"
      hide-footer
    >
      <zwInfo :info="zwInfo"></zwInfo>
    </b-modal>
  </div>
</template>
